.dg-journey-type-selector {
	width: 350px;
	margin: 5px auto;

	[type="radio"] {
		&:not(:checked) {
			+ label {
				&:before {
					@apply bg-white;
					border-width: 1px;
				}
			}
		}

		&:checked {
			+ label {
				&:before {
					border-width: 1px;
				}

				&:after {
					@apply transform-none font-bold text-white;
					content: "\2714\fe0e";
					font-size: 12px;
					border: 1px solid $dg-blue-light;
					background-color: $dg-blue-light;
					text-align: center;
				}
			}
		}

		+ label {
			@apply font-body font-semibold;
			font-size: 16px;
			color: $dg-blue;

			&:before,
			&:after {
				@apply rounded-none;
			}
		}
	}
}

/* MEDIA QUERIES */

@media #{$medium} {
	.dg-journey-type-selector {
		width: 260px;
	}
}

@media #{$small} {
	.dg-journey-type-selector {
		width: 260px;
	}
}

@media #{$xsmall} {
	.dg-journey-type-selector {
		width: calc(100% - 30px);
	}
}
