$brand-primary: #0a396d;
$brand-secondary: #b92234;
$button-size: 18px;
$wrapper-min-width: 120px;
$spacing: 5px;
$dg-gray-lightest: #e6e6e6;
$dg-gray-lighter: #cacaca;
$dg-gray-light: #8d8c8c;
$dg-red: #ae2535;
$dg-blue: #1b365d;

.dg-quantity-wrapper {
	position: relative;
	width: 350px;
	margin: 5px auto 0;
	user-select: none;
	cursor: pointer;
	z-index: 2;

	input {
		position: relative;
		background: #fff;
		height: 55px;
		border: 1px solid $dg-gray-light;
		color: $dg-blue;
		font-family: "Lato", sans-serif;
		font-weight: 600;
		font-size: 16px;
		z-index: 0;
		cursor: pointer;

		&::placeholder {
			color: $dg-gray-light;
		}
	}
}

.dg-pax-quantity-message {
	margin-top: 15px;
	border: 1px solid $dg-red;
	padding: 10px;
	color: $brand-primary;
	line-height: 1.1;

	a {
		color: $brand-secondary;
		text-decoration: underline;

		&:hover,
		&:focus,
		&:active {
			color: $brand-primary;
		}
	}
}

.dg-pax-amount {
	display: flex;
	align-items: center;
	border-bottom: 1px solid $dg-gray-light;
	padding: 1rem 10px;
	user-select: none;

	.dg-quantity-btn {
		display: flex;
		justify-content: center;
		margin-right: $spacing;
		border: 1px solid $brand-secondary;
		color: $brand-secondary;
		height: $button-size;
		width: $button-size;
		border-radius: 50%;
		font-size: 13px;
		align-items: center;
		line-height: 1;

		&:hover {
			border-color: $brand-primary;
			background-color: $brand-primary;
			color: #fff;
			cursor: pointer;
		}

		&.disabled {
			pointer-events: none;
			border-color: $dg-gray-light;
			color: $dg-gray-light;
			cursor: default;

			&:hover {
				background-color: #fff;
			}
		}
	}

	.dg-quantity-amount {
		display: inline-block;
		margin-right: $spacing;
		max-width: 34px;
		border: 0;
		color: $brand-primary;
		font-size: 14px;
		text-align: center;
		padding: 0;
	}
}
