/*
 * Definitions
 */

// List
$list-margin: 				$margin-small 0;
$list-padding: 				0;

// Lists Item
$list-item-margin: 			5px 0;
$list-item-padding: 		$padding-small;

// Unordered List
$unordered-list-icon: 		disc;


/*
 * Lists
 */

// Unordered List
ul.list-styled {
	list-style: $unordered-list-icon;
	padding-left: $list-item-padding;
	li {
		margin: $list-item-margin;
	}
}

// List Inline
.list-inline {
	li {
		display: inline-block;
	}
}