/*
 * Button
 */

// Button
@mixin button() {
	display: inline-block;
	width:auto;
	margin:0;
	font-family: $button-font-family;
	font-weight: $button-font-weight;
	line-height: normal;
	letter-spacing: $button-letter-spacing;
	text-align: $button-text-align;
	text-transform: $button-text-transform;
	white-space: nowrap;
	cursor: pointer;
	border-radius: $button-border-radius;
	@include transition(all $transition-fast ease-in-out);
}

// Button Styles
@mixin button-styles($color, $background, $border) {
	color: $color;
	background: $background;
	border: $border;
}

// Button Theme
@mixin button-theme($color: $button-color, $background: $button-background, $border: $button-border, $hover-color: $button-hover-color, $hover-background: $button-hover-background, $hover-border: $button-hover-border) {
	@include button-styles($color, $background, $border);

	&:not(:disabled):hover,
	&:not(:disabled):focus{
		@include button-styles($hover-color, $hover-background, $hover-border);
	}
}

// Button Icon Theme
@mixin button-icon-theme($fill: $button-icon-fill, $hover-fill: $button-icon-hover-fill) {

	> svg {
		fill: $fill;
	}

	&:not(:disabled):hover,
	&:not(:disabled):focus {

		> svg {
			fill: $hover-fill;
		}
	}
}

// Button Size
@mixin button-size($padding, $font-size) {
	padding: $padding;
	@include font-size($font-size);
}