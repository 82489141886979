.dg-time-selector {
	input {
		@apply relative bg-white font-body font-semibold z-0 cursor-pointer;
		height: 55px;
		border: 1px solid $dg-gray-light;
		color: $dg-blue;
		font-size: 16px;

		&::placeholder {
			color: $dg-gray-light;
		}

		&.active {
			border-color: $dg-red;
		}

		&:disabled {
			@apply opacity-100 cursor-default;
			background: #f2f2f2;
		}

		&.error {
			background: #d8b5b9;
		}
	}
}

.dg-time-selector {
	@apply relative;
	width: 350px;
	height: 55px;
	margin: 5px auto 0;

	label {
		@apply absolute font-body font-semibold;
		top: 8px;
		left: 15px;
		color: $dg-gray-lighter;
		font-size: 12px;
		z-index: 3;

		&.dg-return-date {
			left: 190px;
		}
	}

	input {
		@apply absolute top-0 inline-block;
		width: 175px;
		padding-top: 23px;
		color: $dg-blue;

		&:disabled {
			color: $dg-blue;
			opacity: 1;
			-webkit-text-fill-color: $dg-blue;
		}

		&:first-child {
			width: 176px;

			&:disabled {
				~ .dg-divider-arrow-time {
					opacity: 1;
					background: #f2f2f2;
				}
			}

			&.error {
				~ .dg-divider-arrow-time {
					background: #d8b5b9;
				}
			}
		}

		&:nth-child(2) {
			left: 175px;
		}

		&.in-focus,
		&.active {
			border-color: $dg-red;
			z-index: 1;

			~ .dg-divider-arrow-time {
				border-top-color: $dg-red;
				border-right-color: $dg-red;
			}
		}
	}
}

.dg-divider-arrow-time {
	@apply absolute bg-white;
	top: 50%;
	left: 50%;
	transform: rotate(45deg) translatex(-6px);
	width: 10px;
	height: 10px;
	border-top: 1px solid $dg-gray-light;
	border-right: 1px solid $dg-gray-light;
	z-index: 2;
}

/* MEDIA QUERIES */

@media #{$large} {
	.dg-time-selector {
		width: 310px;

		input {
			color: $dg-blue;

			&:first-child {
				width: 156px;
			}

			&:nth-child(2) {
				width: 155px;
				left: 155px;
			}

			&:disabled {
				color: $dg-blue;
				opacity: 1;
				-webkit-text-fill-color: $dg-blue;
			}
		}
	}
}

@media #{$medium} {
	.dg-time-selector {
		width: 260px;

		label {
			&.dg-return-date {
				left: 145px;
			}
		}

		input {
			color: $dg-blue;
		}

		input:first-child {
			width: 131px;
		}

		input:nth-child(2) {
			width: 130px;
			left: 130px;
		}

		input {
			&:disabled {
				color: $dg-blue;
				opacity: 1;
				-webkit-text-fill-color: $dg-blue;
			}
		}
	}
}

@media #{$small} {
	.dg-time-selector {
		width: 260px;

		label {
			&.dg-return-date {
				left: 145px;
			}
		}

		input {
			color: $dg-blue;
		}

		input:first-child {
			width: 131px;
		}

		input:nth-child(2) {
			width: 130px;
			left: 130px;
		}

		input {
			&:disabled {
				color: $dg-blue;
				opacity: 1;
				-webkit-text-fill-color: $dg-blue;
			}
		}
	}
}

@media #{$xsmall} {
	.dg-time-selector {
		width: calc(100% - 30px);
		height: 45px;

		label {
			&.dg-return-date {
				left: calc(50% + 16px);
			}
		}

		input {
			height: 45px;
			color: $dg-blue;

			&:first-child {
				width: calc(50% + 1px);
				font-size: 12px;
			}

			&:nth-child(2) {
				width: 50%;
				left: 50%;
				font-size: 12px;
			}

			&:disabled {
				color: $dg-blue;
				opacity: 1;
				-webkit-text-fill-color: $dg-blue;
			}
		}
	}
}
