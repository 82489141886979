/*
 * Colors
 */

// 1. Colors
//
$brand-success:     #4CAF50;
$brand-info:        #5bc0de;
$brand-warning:     #FF8831;
$brand-error:       #DB0000;

$body-bg:           #fff;

//Shades
$gray-darkest:      #263238;
$gray-dark:         #455A64;
$gray:              #607D8B;
$gray-light:        #A6A6A6;
$gray-lighter:      #e8e8e8;
$gray-lightest:     #F2F2F2;

//Palette
$brand-primary:      #0a396d;
$brand-secondary:    #b92234;

$brand-light:        #fafafc;
$brand-dark:         #222;

$text-color:        $gray-light;

//Utility
$brand-inverse:      $gray-darkest;