/*
 * General Mixins
 */

// Clearfix
@mixin clearfix() {

	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}

	*zoom: 1;
}

// Show
@mixin show() {
	display: block !important;
	visibility: visible !important;
	opacity: 1 !important;
}

// Hide
@mixin hide() {
	display: none !important;
	visibility: hidden !important;
	opacity: 0 !important;
}

// Sizing
@mixin size($width, $height) {
	width: $width;
	height: $height;
}

// Square Sizing
@mixin square($size) {
	@include size($size, $size);
}

// Overflow Scroll
@mixin overflow-scroll() {
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

// Center Block
@mixin center-block() {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Center
@mixin center() {
	position: absolute;
	top: 50%;
	left: 50%;
	@include translate(-50%, -50%);
}

// Overlay
@mixin cover() {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// Hide text
@mixin hide-text() {
	/*font: ~"0/0" a;*/
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

@mixin text-hide() {
	@include hide-text();
}

//  Hyphens
@mixin hyphens($hyphens: none) {
	-webkit-hyphens: $hyphens;
	-moz-hyphens: $hyphens;
	-ms-hyphens: $hyphens;
	hyphens: $hyphens;
}

//  Tab Size
@mixin tab-size($tab-size: 4) {
	-moz-tab-size: $tab-size;
	-o-tab-size: $tab-size;
	tab-size: $tab-size;
}

// Hide but keep in DOM for Screen Readers
@mixin hide-visually() {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// User Select
@mixin user-select($argument: none) {
	-webkit-user-select: $argument;
	-moz-user-select: $argument;
	-ms-user-select: $argument;
	user-select: $argument;
}

// Appearance
@mixin appearance($appearance) {
	-webkit-appearance: $appearance;
	-moz-appearance: $appearance;
	appearance: $appearance;
}

//  Retina images
@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
	background-image: url("${file-1x}");
	
	@media #{$retina} {
		background-image: url("${file-2x}");
		background-size: $width-1x $height-1x;
	}
}

// Stroke Offset
@mixin stroke-offset($offset) {
	stroke-dasharray: $offset;
	stroke-dashoffset: $offset;
}

// Media Ratio
@mixin media-ratio($width, $height) {
	padding-top: ($height/$width) * 100%;
}

// Column Count
@mixin column-count($count) {
	-webkit-column-count: $count;
	-moz-column-count: $count;
	column-count: $count;
}

// Column Gap
@mixin column-gap($gap) {
	-webkit-column-gap: $gap;
	-moz-column-gap: $gap;
	column-gap: $gap;
}

// Box Sizing
@mixin box-sizing($box-sizing) {
	-webkit-box-sizing: $box-sizing;
	-moz-box-sizing: $box-sizing;
	box-sizing: $box-sizing;
}

//  Truncate
@mixin truncate() {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}