$menu-break: 64rem;
$menu-hamburger-break: #{"(max-width: #{$menu-break - calc(1/16)})"};

/* IE 10-11 related */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.hidden-ie {
		display: none !important;
	}

	.visible-ie {
		display: block !important;
	}
}

/* 800 x 600 tablets related */
@media screen and (max-width: 800px) {
	.visible-tablet {
		display: block !important;
	}

	.hidden-tablet {
		display: none !important;
	}
}

@media #{$medium} {
	.refunds {
		.checkbox-container {
			// min-height: 100px;

			~ .field-error {
				top: -40px;
				left: 5px;
			}
		}
	}
}

@media #{$xsmall} {
	.refunds {
		.checkbox-container {
			height: unset;

			.form-group {
				margin-bottom: 10px;
			}

			~ .field-error {
				top: -40px;
				left: 48px;
			}
		}
	}
}

@media #{$menu-hamburger-break} {
	.form-captcha-container {
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		.g-recaptcha {
			margin: 0 0 20px 0;
		}
	}
}

/* ----------- iPhone 5 and 5S ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}
