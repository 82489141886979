/*
 * Padding
 */

// Padding
.padding-xl {
	padding: $padding-xlarge;
}

.padding-lg {
	padding: $padding-large;
}

.padding-md {
	padding: $padding-medium;
}

.padding-sm {
	padding: $padding-small;
}

.padding-xs {
	padding: $padding-xsmall;
}

// Padding Top
.padding-top-xl {
	padding-top: $padding-xlarge;
}

.padding-top-lg {
	padding-top: $padding-large;
}

.padding-top-md {
	padding-top: $padding-medium;
}

.padding-top-sm {
	padding-top: $padding-small;
}

.padding-top-xs {
	padding-top: $padding-xsmall;
}

// Padding Bottom
.padding-bottom-xl {
	padding-bottom: $padding-xlarge;
}

.padding-bottom-lg {
	padding-bottom: $padding-large;
}

.padding-bottom-md {
	padding-bottom: $padding-medium;
}

.padding-bottom-sm {
	padding-bottom: $padding-small;
}

.padding-bottom-xs {
	padding-bottom: $padding-xsmall;
}

// Padding Left
.padding-left-xl {
	padding-left: $padding-xlarge;
}

.padding-left-lg {
	padding-left: $padding-large;
}

.padding-left-md {
	padding-left: $padding-medium;
}

.padding-left-sm {
	padding-left: $padding-small;
}

.padding-left-xs {
	padding-left: $padding-xsmall;
}

// Padding Right
.padding-right-xl {
	padding-right: $padding-xlarge;
}

.padding-right-lg {
	padding-right: $padding-large;
}

.padding-right-md {
	padding-right: $padding-medium;
}

.padding-right-sm {
	padding-right: $padding-small;
}

.padding-right-xs {
	padding-right: $padding-xsmall;
}