$main-navigation-height: 4rem;
$main-navigation-bg: transparent;
$main-navigation-color: #fff;
$main-navigation-font-size: 1rem;
$main-navigation-hamburger-size: 2rem;
$main-navigation-hamburger-hover-color: $brand-primary;
$main-navigation-font-weight: 300;
$main-navigation-text-transform: uppercase;
$main-navigation-transition-time: $animation-fast;

$main-navigation-shadow: 0;
$main-navigation-content-align: flex-end;

$navigation-item-padding: calc(($main-navigation-height - $main-navigation-font-size) / 2) 1rem;
$navigation-item-hover-bg: $brand-secondary;
$navigation-item-hover-color: #fff;

$main-navigation-logo-height: 2.125rem;
$main-navigation-logo-width: 10.875rem;
$main-navigation-logo-margin: calc(($main-navigation-height - $main-navigation-logo-height) / 2);

$menu-font-reduce: 75rem;
$menu-break: 64rem;
$menu-font-reduce-break: #{"(max-width: #{$menu-font-reduce - calc(1/16)}) and (min-width: #{$menu-break - calc(1/16)})"};
$menu-hamburger-break: #{"(max-width: #{$menu-break - calc(1/16)})"};

.navigation-section {
	display: flex;
	background-color: $main-navigation-bg;
	height: $main-navigation-height;
	@include box-shadow($main-navigation-shadow);

	.brand-logo {
		display: block;
		height: $main-navigation-logo-height;
		width: $main-navigation-logo-width;
		margin: $main-navigation-logo-margin;

		img {
			max-height: $main-navigation-logo-height;
			max-width: $main-navigation-logo-width;
		}
	}
}

.main-navigation {
	display: flex;
	width: 100%;
	justify-content: $main-navigation-content-align;

	a {
		padding: $navigation-item-padding;
		font-size: $main-navigation-font-size;
		color: $main-navigation-color;
		font-weight: $main-navigation-font-weight;
		text-transform: $main-navigation-text-transform;

		&.active,
		&:hover {
			color: $navigation-item-hover-color;
			background-color: $navigation-item-hover-bg;
		}
	}
}

@media #{$menu-font-reduce-break} {
	.main-navigation {
		a {
			font-size: 0.8rem;
		}
	}

	.country-dropdown {
		font-size: 0.8rem;
	}
}

@media #{$menu-hamburger-break} {
	.navigation-section {
		height: auto;

		.brand-logo {
			position: absolute;
		}

		.mobile-navigation[type="checkbox"] {
			position: absolute;
			left: initial;
			visibility: visible;
			margin: 0;
			border: 0;

			&:checked {
				& + .main-navigation {
					a {
						display: block;
					}

					#user {
						display: flex;
					}
				}
			}
		}

		//close
		.main-navigation {
			margin-top: $main-navigation-height;
			flex-direction: column;

			a,
			#user {
				display: none;
				text-align: left;
			}

			#user {
				padding-left: 46px;
				height: 64px;
				align-items: flex-start;

				i {
					left: 15px;
				}
			}
		}
	}

	.mobile-navigation {
		visibility: visible;
		right: 0;
		left: initial;

		&:after {
			content: "|||";
			display: inline-block;
			position: absolute;
			right: $outer-margin;
			top: calc(($main-navigation-height - $main-navigation-hamburger-size) / 2);
			width: $main-navigation-hamburger-size;
			height: $main-navigation-hamburger-size;
			padding: 4px;
			font-size: $main-navigation-hamburger-size;
			@include rotate(-90deg);
			@include transition(all $main-navigation-transition-time);
		}

		&:hover {
			cursor: pointer;
			background: transparent;

			&:after {
				color: $main-navigation-hamburger-hover-color;
			}
		}
	}
}
