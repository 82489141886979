/*
 * Font
 */

// Font
@mixin font($family, $weight, $size) {
	font-family: $family;
	font-weight: $weight;
	@include font-size($size);
}

// Font Family
@mixin font-family($family, $weight) {
	font-family: $family;
	font-weight: $weight;
	font-style: normal;
}

// Font Size
@mixin font-size($rem) {
	font-size: #{$rem}rem;
}

// Font Size Pixels
@mixin font-size-pixel($rem) {
	$pixels: ($rem * 10);
	font-size: $pixels;
	/*font-size: ~"${rem}rem";*/
	font-size: ($rem)rem;
}

// Thin Font
@mixin thin-font() {
	@include font-family($thin-font-family, $thin-font-weight);
}

// Regular Font
@mixin regular-font() {
	@include font-family($regular-font-family, $regular-font-weight);
}

// Medium Font
@mixin medium-font() {
	@include font-family($medium-font-family, $medium-font-weight);
}

// Semibold Font
@mixin semibold-font() {
	@include font-family($semibold-font-family, $semibold-font-weight);
}

// Bold Font
@mixin bold-font() {
	@include font-family($bold-font-family, $bold-font-weight);
}

// Font Face
@mixin font-face($family: arial, $weight: normal, $style: normal) {
	@font-face {
		font-family: '#{$family}';
		src:url('#{$font-path}/#{$family}.eot');
		src:url('#{$font-path}/#{$family}.eot?#iefix') format('embedded-opentype'),
			url('#{$font-path}/#{$family}.svg#icon') format('svg'),
			url('#{$font-path}/#{$family}.woff') format('woff'),
			url('#{$font-path}/#{$family}.ttf') format('truetype'),
			url('#{$font-path}/#{$family}.svg#icon') format('svg');
		font-weight: $weight;
		font-style: $style;
	}
}