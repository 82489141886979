/*
 * Definitions
 */

// Button
$button-padding: 10px 20px !default;
$button-height: auto !default;
$button-font-family: $font-family !default;
$button-font-weight: $medium-font-weight !default;
$button-font-size: $font-size-xsmall !default;
$button-color: $gray-dark !default;
$button-letter-spacing: 0.05rem !default;
$button-text-align: center !default;
$button-text-transform: uppercase !default;
$button-background: #fff !default;
$button-border: 1px solid $gray-lighter !default;
$button-border-radius: $border-radius !default;

// Button Hover
$button-hover-color: $gray-dark !default;
$button-hover-background: darken($button-background, 10%) !default;
$button-hover-border: 1px solid darken($gray-lighter, 10%) !default;

// Button Disabled
$button-disabled-opacity: 0.7 !default;

// Button Block
$button-block-text-align: center !default;

// Button
button,
.btn {
	@include button();
	@include button-size($button-padding, $button-font-size);
	@include button-theme(
		$button-color,
		$button-background,
		$button-border,
		$button-hover-color,
		$button-hover-background,
		$button-hover-border
	);
}

// Button Disabled
button:disabled,
.btn.disabled {
	cursor: default;
	opacity: $button-disabled-opacity;
}

// iOS "clickable elements" fix for role="button"
[role="button"] {
	cursor: pointer;
}

// Button Block
.btn-block {
	display: block;
	width: 100%;
	text-align: $button-block-text-align;
}

.dg-rounded-primary-btn {
	@apply relative flex cursor-pointer appearance-none justify-center whitespace-normal rounded-full border-2 border-solid border-brand-primary bg-brand-primary font-body font-bold normal-case leading-none tracking-normal text-white;
	text-align: center;
	font-size: 18px;
	min-width: 180px;
	padding: 10px 35px 10px 15px;

	&.dg-insurance-btn {
		min-width: 120px;
	}

	&:not(.disabled):hover {
		@apply border-2 border-solid border-brand-primary bg-white text-brand-primary;

		&:after {
			@apply font-bold;
		}
	}

	&:not(.disabled):focus,
	&:not(.disabled):active {
		@apply border-2 border-solid border-brand-primary bg-brand-primary text-white;
	}

	&:after {
		@apply absolute font-normal vertical-center;
		font-family: "jetsmart-home" !important;
		right: 5px;
		content: "\e911"; // DEVNOTE Might change if icons change
		font-size: 25px;
	}

	&.no-click {
		@apply cursor-default opacity-50;
	}

	/* &.smaller {
    } */
}

.dg-rounded-secondary-btn {
	@apply relative flex cursor-pointer appearance-none justify-center whitespace-normal rounded-full border-2 border-solid border-brand-secondary bg-brand-secondary font-body font-bold normal-case leading-none tracking-normal text-white;
	text-align: center;
	font-size: 18px;
	min-width: 180px;
	padding: 10px 15px;

	&:not(.disabled):hover {
		@apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
	}

	&:not(.disabled):focus,
	&:not(.disabled):active {
		@apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
	}

	&.no-click {
		opacity: 0.5;
		cursor: default;
	}

	&.with-arrow {
		padding: 10px 35px 10px 15px;

		&:after {
			@apply absolute font-normal vertical-center;
			font-family: "jetsmart-home" !important;
			right: 5px;
			content: "\e911"; // DEVNOTE Might change if icons change";
			font-size: 25px;
		}
	}

	/* &.smaller {
    } */
}

.dg-rounded-reverse-btn {
	@apply relative inline-flex cursor-pointer appearance-none justify-center whitespace-normal rounded-full border-2 border-solid border-brand-secondary bg-white font-body font-bold normal-case leading-none tracking-normal text-brand-secondary;
	text-align: center;
	font-size: 18px;
	min-width: 180px;
	padding: 10px 15px;

	&:not(.disabled):hover {
		@apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;
	}

	&:not(.disabled):focus,
	&:not(.disabled):active {
		@apply border-2 border-solid border-brand-secondary bg-brand-secondary text-white;

		&:hover {
			@apply border-2 border-solid border-brand-secondary bg-white text-brand-secondary;
		}
	}
}

@media #{$medium} {
	.dg-rounded-primary-btn {
		&.no-click {
			@apply cursor-default bg-gray-300 text-gray-200 opacity-100;
			border-color: $gray-light;

			&:focus,
			&:hover {
				@apply bg-gray-300 text-gray-200;
				border-color: $gray-light;
			}
		}
	}

	.dg-rounded-secondary-btn {
		&.no-click {
			@apply cursor-default bg-gray-300 text-gray-200 opacity-100;
			border-color: $gray-light;

			&:focus,
			&:hover {
				@apply bg-gray-300 text-gray-200;
				border-color: $gray-light;
			}
		}
	}

	.dg-rounded-reverse-btn {
		&:not(.disabled):hover {
			&:after {
				@apply font-normal;
			}
		}
	}
}

@media #{$small} {
	.dg-rounded-primary-btn {
		font-size: 15px;
		min-width: 130px;

		&:after {
			font-size: 20px;
		}

		&.no-click {
			@apply cursor-default bg-gray-300 text-gray-200 opacity-100;
			border-color: $gray-light;

			&:focus,
			&:hover {
				@apply bg-gray-300 text-gray-200;
				border-color: $gray-light;
			}
		}

		&.longer {
			min-width: 162px;
		}

		/* &.smaller {
        }*/
	}

	.dg-rounded-secondary-btn {
		font-size: 15px;
		min-width: 130px;

		&.no-click {
			@apply cursor-default bg-gray-300 text-gray-200 opacity-100;
			border-color: $gray-light;

			&:focus,
			&:hover {
				@apply bg-gray-300 text-gray-200;
				border-color: $gray-light;
			}
		}

		&.longer {
			min-width: 162px;
		}

		/* &.smaller {
        }*/
	}

	.dg-rounded-reverse-btn {
		font-size: 15px;
		min-width: 130px;

		&:after {
			font-size: 20px;
		}

		&:not(.disabled):hover {
			&:after {
				@apply font-normal;
			}
		}

		&.longer {
			min-width: 162px;
		}
	}
}

@media #{$xsmall} {
	.dg-rounded-primary-btn {
		font-size: 15px;
		min-width: 130px;

		&:after {
			font-size: 20px;
		}

		&.no-click {
			@apply cursor-default bg-gray-300 text-gray-200 opacity-100;
			border-color: $gray-light;

			&:focus,
			&:hover {
				@apply bg-gray-300 text-gray-200;
				border-color: $gray-light;
			}
		}

		&.longer {
			min-width: 162px;
		}

		/* &.smaller {
        } */
	}

	.dg-rounded-secondary-btn {
		font-size: 15px;
		min-width: 130px;

		&.longer {
			min-width: 162px;
		}

		/* &.smaller {
        }*/
	}

	.dg-rounded-reverse-btn {
		font-size: 15px;
		min-width: 130px;

		&:after {
			font-size: 20px;
		}

		&:not(.disabled):hover {
			&:after {
				@apply font-normal;
			}
		}

		&.longer {
			min-width: 162px;
		}
	}
}
