/*
 * Definitions
 */

// Button Inverse
$button-inverse-color: 				$brand-light !default;
$button-inverse-background: 		$brand-inverse !default;
$button-inverse-border: 			none !default;
$button-inverse-hover-color: 		$button-inverse-color !default;
$button-inverse-hover-background: 	darken($button-inverse-background, 10%) !default;
$button-inverse-hover-border: 		none !default;

// Button Primary
$button-primary-color: 				#fff !default;
$button-primary-background: 		$brand-primary !default;
$button-primary-border: 			1px solid $brand-primary !default;
$button-primary-hover-color: 		$brand-primary !default;
$button-primary-hover-background: 	#fff !default;
$button-primary-hover-border: 		1px solid $brand-primary !default;

// Button Secondary
$button-secondary-color: 			#fff !default;
$button-secondary-background: 		$brand-secondary !default;
$button-secondary-border: 			1px solid $brand-secondary !default;
$button-secondary-hover-color: 		$brand-secondary !default;
$button-secondary-hover-background: #fff !default;
$button-secondary-hover-border: 	1px solid $brand-secondary !default;

// Button Border
$button-border-color: 				$brand-secondary !default;
$button-border-border: 				1px solid $button-border-color !default;
$button-border-background: 			transparent !default;
$button-border-hover-color: 		#fff !default;
$button-border-hover-border: 		1px solid $button-border-color !default;
$button-border-hover-background: 	$button-border-color !default;

// Button Text
$button-text-color: 				$brand-dark !default;
$button-text-hover-color: 			$brand-primary !default;


/*
 * Button Styles
 */

// Button Inverse
.btn-inverse {
	@include button-theme($button-inverse-color, $button-inverse-background, $button-inverse-border, $button-inverse-hover-color, $button-inverse-hover-background, $button-inverse-hover-border);
}

// Text Button
.btn-text {
	@include button-theme($button-text-color, transparent, none, $button-text-hover-color, transparent, none);
}

// Button Primary
.btn-primary {
	@include button-theme($button-primary-color, $button-primary-background, $button-primary-border, $button-primary-hover-color, $button-primary-hover-background, $button-primary-hover-border);
}

// Button Secondary
.btn-secondary {
	@include button-theme($button-secondary-color, $button-secondary-background, $button-secondary-border, $button-secondary-hover-color, $button-secondary-hover-background, $button-secondary-hover-border);
}

// Button Border
.btn-border {
	@include button-theme($button-border-color, $button-border-background, $button-border-border, $button-border-hover-color, $button-border-hover-background, $button-border-hover-border);
}