$js-header-height: 86px;
$header-main-color: #a62733;
$header-secondary-color: #204071;

.main-header {
	position: fixed;
	top: 0;
	width: 100%;
	height: $js-header-height;
	font-size: 1rem;
	text-align: center;
	transition: top 0.25s ease-in-out, height 0.25s ease-in-out;
	z-index: 99998;

	&.collapsed {
		top: -$js-header-height;
		overflow: hidden;

		.right {
			border-bottom: none;
		}

		.opened {
			max-height: 0;
		}
	}

	&.condensed {
		height: $js-header-height;
	}

	.mobile-navigation[type="checkbox"] {
		display: none;
	}

	div.mobile-navigation {
		display: none;
	}

	.nav-bg {
		border-bottom: 4px solid $header-main-color;
		background: #fff;
	}

	.nav-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: $js-header-height;
		margin: 0 auto;
		max-width: 1352px;
		user-select: none;
		transition: height 0.25s ease-in-out;

		.dg-jetsmart-logo {
			display: flex;
			width: 204px;
			height: $js-header-height;
			margin: 0;
			align-items: center;
			cursor: pointer;
			transition: height 0.25s ease-in-out;

			img {
				max-height: 42px;
				max-width: 180px;
				transition: max-height 0.25s ease-in-out;
			}
		}
	}
}

.menu-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
}

.american-member-data-container {
	&:after {
		@apply mt-[6px] box-border block rounded-xl;
		content: "";
		border-bottom: solid 2px #fff;
		transform: scaleX(0);
		transition: transform 250ms ease-in-out;
	}

	&:hover,
	&.dropdown-opened {
		&:after {
			transform: scale(1);
			transition: transform 250ms ease-in-out;
		}
	}
}

.american-displayed-name {
	&:after {
		@apply absolute left-auto box-border;
		content: "";
		display: none;
		top: 60%;
		right: 7px;
		width: 10px;
		height: 10px;
		border-bottom: 5px solid transparent;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid $header-secondary-color;
	}

	&.dropdown-opened {
		&:after {
			border-top: 5px solid transparent;
			border-bottom: 5px solid $header-secondary-color;
			top: 30%;
		}
	}
}

.dg-culture-dropdown-opener {
	display: flex;
	position: relative;
	line-height: 0.5;
	cursor: default;

	> a {
		width: 185px;
		height: $js-header-height;
		position: relative;
		transition: background-color 0.25s ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		color: $header-secondary-color;
		transition: height 0.25s ease-in-out;

		&:not(.no-arrow) {
			cursor: pointer;

			&:hover,
			&.opened {
				background-color: $header-secondary-color;
				color: #fff;

				span {
					color: #fff;
				}
			}

			&:hover {
				span {
					&:last-child {
						&:after {
							border-top: 5px solid #fff;
						}
					}
				}
			}

			&.opened {
				span {
					&:last-child {
						&:after {
							border-top: 5px solid transparent;
							border-bottom: 5px solid #fff;
						}
					}
				}
			}

			span {
				position: relative;

				&:last-child {
					&:after {
						display: block;
						box-sizing: border-box;
						content: " ";
						position: absolute;
						top: 50%;
						right: -15px;
						left: auto;
						transform: translateY(-50%) scaleX(1);
						width: 10px;
						height: 10px;
						border-bottom: 5px solid transparent;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 5px solid $header-secondary-color;
					}
				}
			}
		}
	}

	.flag {
		background-image: url("/images/header/flags.svg");
		background-size: 350px 350px;
		background-repeat: no-repeat;
		border: 2px solid #fff;
		border-radius: 50%;
		border-bottom-right-radius: 0;
		width: 22px;
		height: 22px;
		margin-right: 10px;
	}

	.flag-ar {
		background-position: -143px -184px;
	}
	.flag-bo {
		background-position: -244px -116px;
	}
	.flag-br {
		background-position: -294px -184px;
	}
	.flag-cl {
		background-position: -143px -116px;
	}
	.flag-co {
		background-position: -42px -184px;
	}
	.flag-cr {
		background-position: -294px -116px;
	}
	.flag-ec {
		background-position: -42px -116px;
	}
	.flag-gt {
		background-position: -294px -48px;
	}
	.flag-hn {
		background-position: -144px -48px;
	}
	.flag-mx {
		background-position: -244px -184px;
	}
	.flag-ni {
		background-position: -44px -48px;
	}
	.flag-pa {
		background-position: -94px -116px;
	}
	.flag-pe {
		background-position: -92px -184px;
	}
	.flag-py {
		background-position: -194px -48px;
	}
	.flag-sv {
		background-position: -94px -48px;
	}
	.flag-us {
		background-position: -194px -184px;
	}
	.flag-uy {
		background-position: -244px -48px;
	}
	.flag-ve {
		background-position: -194px -116px;
	}
	.flag-es {
		background-position: -42px -252px;
	}
	.flag-gb {
		background-position: -93px -251px;
	}

	.cultures {
		display: block;
		position: absolute;
		top: $js-header-height;
		left: -25px;
		right: 0;
		background: $header-secondary-color;
		color: #fff;
		line-height: 1;
		transition: max-height 0.25s ease-in-out;
		overflow: hidden;
		z-index: 100;

		h2 {
			margin: 10px 20px;
			text-transform: uppercase;
			font-family: "Lato", sans-serif;
			font-size: 18px;
			font-weight: 600;
			border-bottom: 1px solid #fff;
			text-align: left;
			line-height: 1;
			padding: 10px 0;
		}

		a {
			width: 100%;
			display: flex;
			padding: 10px 20px;
			align-items: center;
			color: #fff;
			cursor: pointer;
		}
	}
}

.dg-header-dropdown-label {
	@apply relative cursor-pointer;

	&.portal {
		min-width: 120px;
	}

	&:hover {
		&:after {
			border-top: 5px solid #fff;
		}
	}

	&.opened {
		@apply text-white;
		background-color: $header-secondary-color;

		&:after {
			border-top: 5px solid transparent;
			border-bottom: 5px solid #fff;
		}
	}

	&:after {
		@apply absolute left-auto block;
		box-sizing: border-box;
		content: " ";
		top: 50%;
		right: 7px;
		transform: translateY(-50%) scaleX(1);
		width: 10px;
		height: 10px;
		border-bottom: 5px solid transparent;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid $header-secondary-color;
	}
}

.dg-header-dropdown-list {
	@apply absolute w-full overflow-hidden text-white;
	top: 86px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: #002142;
	max-height: 0;

	&.opened {
		max-height: 150px;
		transition: max-height 0.25s ease-in-out;
	}

	a {
		@apply flex border-t border-solid border-brand-secondary py-6 pl-6 align-middle text-white;
		font-family: "Lato", sans-serif;
		font-size: 15px;
		font-weight: 600;

		&:hover,
		&:focus {
			@apply text-white underline;
		}
	}
}

.login-container {
	display: flex;
	position: relative;
	line-height: 0.5;

	li {
		height: $js-header-height;
		display: inline-block;
		position: relative;
		transition: background-color 0.25s ease-in-out, height 0.25s ease-in-out;

		&:hover,
		&.opened {
			background-color: $header-secondary-color;

			> a {
				color: #fff;

				span {
					&:last-child {
						&:after {
							transform: scaleX(1);
						}
					}
				}
			}

			&.user-data span.dg-banco-estado-name {
				color: #fff;
			}

			&.user-data span.dg-banco-estado-org-name {
				color: #fff;
			}
		}

		> a {
			height: 86px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: 16px;
			padding: 0 24px;
			color: $header-secondary-color;
			cursor: pointer;
			transition: height 0.25s ease-in-out;

			> span {
				position: relative;
				white-space: nowrap;
				font-size: 16px;

				&.dg-header-dropdown-label-title {
					display: flex;
					align-items: center;

					span {
						font-family: "Gotham-Bold", sans-serif;
						font-size: 16px;
						font-style: italic;
						position: relative;
						top: 1px;
						margin-left: 5px;
					}
				}

				&.smaller {
					display: block;
					font-size: 12px;
					margin-top: 2px;
				}

				&:last-child {
					&:after {
						position: absolute;
						top: 18px;
						left: 0;
						right: 0;
						box-sizing: border-box;
						display: block;
						content: "";
						border-bottom: solid 2px #fff;
						transform: scaleX(0);
						transition: transform 250ms ease-in-out;
					}
				}
			}
		}

		&.be-pointer-6,
		&.be-pointer-5 {
			> a {
				> span {
					&:last-child:not(.header-todosuma-summary) {
						&:last-child {
							&:after {
								top: 30px;
							}
						}
					}
				}
			}
		}

		&#user {
			width: unset;
			min-width: 185px;
			padding: 0 10px;

			> a {
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
	}
}

/* MEDIA QUERIES */

@media #{$medium-up} {
	.main-header {
		&.semi-collapsed {
			height: 55px;

			.nav-container {
				height: 55px;

				.dg-jetsmart-logo {
					height: 40px;

					img {
						max-height: 36px;
					}
				}
			}

			.login-container {
				li {
					height: 55px;

					> a {
						height: 55px;
					}
				}
			}

			.dg-culture-dropdown-opener {
				.cultures {
					top: 55px;
				}

				> a {
					height: 55px;
				}
			}
		}
	}
}

@media #{$large-down} {
	.main-header {
		.nav-container {
			padding-left: 0;
			padding-right: 0;
			max-width: 1155px;
		}
	}
}

@media #{$medium} {
	.main-header {
		.nav-container {
			margin: 0 25px;
		}
	}

	.login-container {
		li {
			font-size: 15px;

			a {
				font-size: 15px;

				span {
					font-size: 14px;
				}
			}

			> a {
				span {
					&.dg-header-dropdown-label-title {
						flex-direction: column;

						&:last-child {
							&:after {
								top: 31px;
							}
						}

						span {
							margin-top: 8px;
						}
					}
				}
			}
		}

		#user {
			width: unset;
			min-width: 140px;
			padding: 0 5px;

			> a {
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
	}

	.dg-culture-dropdown-opener {
		> a {
			width: 140px;
			font-size: 15px;
		}
	}
}

@media #{$small-down} {
	.main-header {
		padding-left: 0;
		height: 55px;

		.nav-container {
			margin-right: 25px;
			margin-left: 0;
			padding-left: 25px;
			height: 55px;

			&.full-width {
				padding-left: 0;
				margin-left: 10px;
				margin-right: 10px;

				&.cug-header {
					padding-left: 0;
				}
			}

			.dg-jetsmart-logo {
				height: 40px;

				img {
					max-height: 30px;
				}
			}
		}

		.right {
			@apply fixed left-0 right-0 m-0 overflow-hidden bg-white p-0;
			max-height: 0;
			top: 55px;
			z-index: 99;
			border-bottom: 4px solid $header-main-color;
			transition: all 0.5s ease-in-out;

			li {
				@apply mx-auto my-0 block h-auto w-1/2;
				min-height: 60px;
				text-align: center;
				@include font-size($font-size-small);
				color: $header-secondary-color;

				> a {
					span {
						&:last-child {
							&:after {
								top: 29px;
							}
						}
					}
				}

				// a {
				//     display: flex;
				// }
			}

			.dg-header-dropdown-opener {
				@apply w-full;
			}
		}

		.dg-culture-dropdown-opener {
			margin-right: 43px;

			.cultures {
				top: 55px;
			}

			a {
				width: 140px;
			}

			> a {
				width: 120px;
				height: 55px;
				padding-right: 10px;
			}

			.culture-selector {
				.flag-pe {
					background-position: -93px -184px;
				}

				.flag-br {
					background-position: -295px -184px;
				}
			}
		}

		.login-container {
			display: block;
			line-height: normal;

			&.american-mobile-menu {
				li {
					display: none;
				}
			}

			li {
				> a {
					height: unset;
					padding: 20px 0;

					span {
						@apply whitespace-normal;
						font-size: 15px;
					}
				}
			}
		}

		div.mobile-navigation {
			display: block;
			position: absolute;
			top: 40px;
			right: 5px;
			right: 0px;
			width: 40px;
			text-transform: uppercase;
			color: $header-secondary-color;
			font-size: 10px;
			text-align: center;

			&:after {
				display: none;
			}
		}

		.mobile-navigation[type="checkbox"] {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			left: auto;
			bottom: 0;
			width: 54px;
			height: auto;
			margin: 0;
			border: 0;
			visibility: visible;
			opacity: 0.01;
			color: $header-main-color;
			z-index: 100;

			& ~ .opened {
				display: none;
			}

			&:checked {
				& + .right {
					max-height: 435px;
					transition: all 0.25s ease-in-out;
				}

				& ~ .closed {
					display: none;
				}

				& ~ .opened {
					display: block;
				}
			}

			&:after {
				display: none;
			}

			&:hover {
				cursor: pointer;
				background: transparent;

				&:after {
					color: $brand-primary;
				}
			}
		}

		.menu-container {
			&:after {
				content: "|||";
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: 14px;
				top: 10px;
				width: 20px;
				height: 30px;
				font-size: 30px;
				font-weight: 900;
				transform: rotate(-90deg) scaleY(1.3);
				@include transition(all $main-navigation-transition-time);
				color: $header-main-color;
				background: white;
			}
		}
	}

	.american-displayed-name {
		&:after {
			@apply block;
		}
	}

	.site-content {
		padding-top: 0px;

		&.dg-bancoe-bar-open {
			padding-top: 50px;
		}
	}

	.dg-header-dropdown-list {
		@apply static block;
		border-radius: 0;

		a {
			@apply justify-center px-0 py-3;
			text-align: center;
		}
	}

	.dg-header-dropdown-label {
		&:after {
			right: 25px;
		}
	}
}

@media #{$xsmall} {
	.main-header {
		height: 55px;

		.menu-container {
			&:after {
				width: 20px;
				transform: rotate(-90deg) scaleY(1.3);
			}
		}

		.nav-container {
			margin-left: 0;
			padding-left: 25px;
			height: 55px;

			&.full-width {
				margin-left: 10px;
				margin-right: 10px;
			}

			.dg-jetsmart-logo {
				height: 40px;

				img {
					max-height: 30px;
				}
			}
		}

		div.mobile-navigation {
			top: 35px;
		}

		.menu-container {
			&:after {
				top: 7px;
			}
		}

		.dg-culture-dropdown-opener {
			margin-right: 43px;

			img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 5px;
			}

			.cultures {
				top: 55px;

				h2 {
					margin: 0 20px 10px;
					padding-bottom: 5px;
				}

				ul {
					margin-top: 2px;
				}

				li {
					padding: 3px 20px;
				}
			}

			> a {
				width: 120px;
				height: 55px;
				padding-right: 15px;
			}
		}

		.right {
			top: 55px;
		}
	}

	.site-content {
		padding-top: 0px;

		&.dg-bancoe-bar-open {
			padding-top: 50px;
		}
	}
}
