/* Global */

.delayed {
	pointer-events: none;
	opacity: 0;
}

#carousel {
	min-height: 600px;
}

/* IE <9 related */

.visible-ie {
	display: none;
}

/* 800 x 600 tablets related */

.visible-tablet {
	display: none !important;
}

/* Sticky footer */

.main-wrap {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.site-content {
	padding-top: 30px;
	flex: 1;
	background: #f7f7f7;

	&.dg-bancoe-bar-open {
		padding-top: 100px;
	}
}

.end-container {
	padding-bottom: $margin-xlarge;
}

$main-menu-item-height: 60px;
$main-menu-border-size: 2px;
$main-menu-bg-color: $brand-primary;
$header-logo-size: 36px;

.home-icons {
	margin-top: $margin-small;

	a {
		display: block;
		width: 100%;
		box-shadow: $box-shadow-small;
		background: #fff;

		&:hover {
			filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
			/* Firefox 3.5+ */
			filter: gray;
			/* IE6-9 */
			-webkit-filter: grayscale(100%);
			/* Chrome 19+ & Safari 6+ */
			box-shadow: $box-shadow-large;
		}

		&.emphasis {
			display: block;
			width: 100%;
			box-shadow: $box-shadow-small;

			&:hover {
				filter: none;
				-webkit-filter: none;
				box-shadow: 6px 6px 10px 0 $brand-secondary;

				img {
					filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
					/* Firefox 3.5+ */
					filter: gray;
					/* IE6-9 */
					-webkit-filter: grayscale(100%);
					/* Chrome 19+ & Safari 6+ */
				}
			}
		}
	}
}

/* Admin */

.dg-admin-select {
	padding: 10px;
	border-color: #a6a6a6;
	font-size: 14px;
}

/* Static content */

.static-header-image {
	margin: 0 0 0 0;
	display: flex;
	align-items: center;
	height: 200px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	&.xl {
		height: 330px;
	}

	&.group-form {
		height: 320px;
		background-size: contain;
		background-position: left center;
		background-color: #00abc8;

		.container {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			width: 100%;
			font-family: "ClanOT-Black", sans-serif;
			font-style: italic;
			font-size: 38px;
			color: #fff;
			text-transform: uppercase;

			span {
				text-align: right;
				font-family: "ClanOT-Black", sans-serif;
				font-style: normal;
				color: #fff;
				text-transform: none;
			}
		}
	}

	h1 {
		font-size: 30px;
		text-align: center;
		color: #fff;
		width: 100%;
		font-weight: bold;
	}
}

.static-content-body {
	margin: 40px 0;

	.static-content-title,
	.static-content-sub {
		text-align: center;
		color: $brand-primary;
		font-size: 28px;

		&.text-justified {
			text-align: justify;
		}
	}

	.static-content-sub {
		margin: 30px;
		font-size: 22px;
		font-weight: 300;

		&.spaced {
			line-height: 32px;
		}
	}

	.faq-item-container {
		text-align: center;
		margin: 30px 0 0 0;

		i {
			font-size: 100px;
			color: $brand-info;
		}
	}

	.faq-title {
		font-weight: bold;
		font-size: 20px;
		margin: 10px 0;
		color: $brand-primary;

		&:hover {
			a {
				color: $brand-secondary;
			}
		}
	}

	.faq-item-title {
		font-weight: bold;
		margin: 40px 0 10px;
		line-height: 1.4rem;
		color: $brand-primary;
	}

	.faq-content-italic {
		font-style: italic;
	}

	.faq-content-underlined {
		text-decoration: underline;
		padding-top: 10px;
	}

	.faq-content-list {
		color: $brand-primary;

		&.sub-list-styled {
			li {
				margin-left: 20px;

				&:before {
					content: "-";
					position: absolute;
					margin-left: -20px;
				}
			}
		}
	}

	.static-content-text {
		margin: 50px 0;

		p {
			color: $brand-primary;
		}
	}

	.faq-text {
		a,
		a:visited,
		a:active {
			color: $brand-secondary;
		}

		a:hover {
			color: $brand-primary;
		}
	}

	.static-breadcrumb-list {
		display: flex;
		margin: 40px 0;

		li {
			margin-right: 10px;
			position: relative;

			a {
				color: $gray-light;

				i {
					font-size: 10px;
					margin: 0 0 0 5px;
				}
			}

			&.active {
				a {
					color: $brand-primary;
				}
			}

			&:hover {
				a {
					color: $brand-primary;
				}
			}
		}
	}

	.social-share-list {
		display: flex;
		justify-content: center;
		margin: 0 0 50px 0;

		li {
			line-height: 40px;
			vertical-align: middle;
			margin: 0 5px;

			a {
				background-color: $brand-primary;
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				vertical-align: middle;
				border-radius: 50%;
				text-align: center;
				color: #fff;
			}
		}
	}

	.share-box-list {
		display: flex;
		justify-content: center;
		align-items: stretch;
		margin: 50px 0;

		li {
			border: 1px solid $gray-light;
			width: 100%;

			a {
				display: flex;
				justify-content: center;
				padding: 40px 0;

				i {
					margin: 0 10px;
					color: $brand-info;
					text-align: center;
					font-size: 26px;
					height: 50px;
					width: 50px;
					line-height: 24px;
					vertical-align: middle;
					padding: 10px;
					border: 4px solid $brand-info;
					border-radius: 50%;
				}

				.social-container {
					margin: 0 0 0 10px;

					label {
						font-weight: 300;
						display: block;
					}

					.social-title {
						margin: 5px 0 0 0;
						font-weight: bold;
						font-size: 18px;
						color: $brand-primary;
					}
				}
			}

			&:hover {
				background-color: $brand-primary;
				cursor: pointer;

				a {
					i {
						color: #fff;
						border-color: #fff;
					}

					.social-container {
						label {
							color: #fff;
						}

						.social-title {
							color: #fff;
						}
					}
				}
			}
		}
	}
}

/* Contact page */

/* Google reCaptcha */

.form-captcha-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;

	/*>div{
         margin: 0 20px 0 0;
    }*/
	> .error iframe {
		border: 1px solid $input-error-color;
	}
}

.field-error {
	color: $input-error-color;
	margin-top: 1px;
	position: absolute;
}

.error-summary {
	color: $input-error-color;
}

.g-recaptcha {
	margin: 0 20px 0 0;
}

/* Result Message */

.result-msg-container {
	margin: 100px 0;
	text-align: center;

	h1 {
		font-size: 26px;
		margin: 50px 0;
		color: $brand-secondary;
	}

	h2 {
		font-size: 22px;
		margin: 20px 0;
		color: $brand-primary;
	}

	i {
		font-size: 60px;
		color: $brand-info;
	}
}

/*  Error page  */

.error-body {
	background-color: #36cde2;
	background-image: url("../images/cloud.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	color: #fff;

	.error-logo {
		width: 300px;
		margin: 100px 0 50px;
	}
}

.error-body,
.error-page {
	.error-title,
	h1 {
		font-weight: 300;
		font-size: 44px;
		margin: 0 0 50px 0;

		&.jt-icon {
			display: block;
			color: #36cde2;
			margin: 0 auto 50px;
			text-align: center;
			font-size: 60px;
		}
	}

	.error-content {
		font-size: 25px;
		margin-bottom: 50px;
		font-weight: 300;
		line-height: 1.4em;
	}
}

.error-page {
	margin-top: 50px;
}

.error-btn {
	margin-bottom: 50px;
}

/* Refunds */

.refunds {
	textarea {
		height: 140px;
	}

	select {
		display: block;
		width: 100%;
		font-family: "Lato", sans-serif;
		font-weight: 400;
		border-radius: 0;
		transition: all 0.2s ease-in-out;
		box-sizing: border-box;
		padding: 9px 15px;
		font-size: 1rem;
		background: #fff;
		border: 1px solid #a6a6a6;

		option {
			padding: 9px 15px;
		}
	}

	::placeholder,
	select > option:first-child {
		color: #555;
	}

	.btn {
		margin: 20px 0;
	}

	select:disabled,
	input:disabled {
		cursor: no-drop;
		color: #a6a6a6;
		background: #a6a6a6;
		border: 1px solid #8d8d8d;

		&::placeholder {
			color: #a6a6a6;
		}
	}

	.checkbox-container {
		width: 100%;
		// min-height: 130px;
		border: 1px solid $gray-light;
		padding: 10px;
		margin-bottom: 20px;

		// &.xl {
		//     height: 150px;
		// }

		label[for] {
			color: $brand-primary;
		}

		~ .field-error {
			display: block;
			position: relative;
			top: -50px;
			left: 10px;
		}
	}

	label[for="accept"] {
		color: $brand-secondary;
	}
}

/* Check-in tooltips */

.dg-enhanced-input {
	@apply relative w-full inline-block box-border max-w-full m-0;
	padding: 20px 0;
	font-size: 16px;

	label[for] {
		@apply absolute text-brand-secondary font-black bottom-0 right-0 pointer-events-none block w-full overflow-hidden whitespace-nowrap;
		top: -18px;
		line-height: 43px;
		padding-left: 15px;
		font-size: 14px;
		left: 7px;
		text-align: left;
	}

	input {
		@apply bg-white block w-full m-0 text-brand-secondary border border-solid border-brand-secondary appearance-none box-border;
		height: 53px;
		padding-top: 24px;
		padding-left: 14px;
		border-radius: 5px;
		line-height: 53px;
		font-size: 20px;
		text-align: left;

		&:focus {
			& + .checkin-tooltip {
				@apply block;
			}
		}
	}
}

.checkin-tooltip {
	@apply absolute;
	display: none;
	bottom: 60px;
	left: 50%;
	transform: translateX(-50%);
	color: $gray-darkest;

	&.baggage {
		bottom: auto;
		top: -110px;
	}

	span {
		display: block;
		margin: 10px;
	}

	.arrow-box {
		@apply relative bg-white;
		width: 220px;
		padding: 15px;
		border: 1px solid #333;
		border-radius: 6px;
		box-shadow: 5px 5px 10px #ccc;
		z-index: 10000;
		line-height: 1.1;
	}

	.arrow-box:after,
	.arrow-box:before {
		@apply h-0 w-0 absolute pointer-events-none;
		left: 50%;
		border: solid transparent;
		content: " ";
	}

	.arrow-box:after {
		top: calc(100% - 1px);
		border-color: rgba(255, 255, 255, 0);
		border-top-color: #fff;
		border-width: 30px;
		margin-left: -30px;
	}

	.arrow-box:before {
		top: 100%;
		border-color: rgba(51, 51, 51, 0);
		border-top-color: #333;
		border-width: 31px;
		margin-left: -31px;
	}
}

.refunds {
	label[for="accept"] {
		height: auto;
	}
}

/* org name in username for CUG2 */

.user-data {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;

	i {
		position: absolute;
		left: 0;
		top: 1px;
	}

	span {
		display: block;
		line-height: 1;
		white-space: nowrap;

		&.orgname {
			margin-top: 5px;
			font-size: 13px;
		}

		&.dg-banco-estado-name {
			font-family: "Gotham-Bold", sans-serif;
			font-size: 20px;
			color: #ee801d;
			white-space: normal;
			max-width: 260px;
		}

		&.dg-banco-estado-org-name {
			font-family: "Gotham-Book", sans-serif;
			font-size: 12px;
			color: #2c3c45;
		}

		&.dg-american-name {
			color: #00aec7;
			font-weight: bold;
		}
	}

	&:hover {
		span {
			&.dg-american-name {
				color: white;
				font-weight: bold;
			}
		}
	}
}

/* MEDIA QUERIES */

@media #{$medium} {
	.user-data {
		span {
			&.dg-banco-estado-name {
				font-size: 16px;
			}
		}
	}
}

@media #{$xsmall} {
	#carousel {
		margin-top: 20px;
		min-height: 270px;
	}
}
