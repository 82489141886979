/*
 * Fonts
 */

// Fonts
//
$font-path:                 	'../../fonts' !default;
$font-family:                   'Lato', sans-serif !default;

// Generic Font Weights
//
$thin-font-weight:          	300 !default;
$regular-font-weight:       	400 !default;
$medium-font-weight:        	500 !default;
$semibold-font-weight:      	600 !default;
$bold-font-weight:          	700 !default;

// Font Sizes
//
$font-size-xlarge:       		1.625 !default;		//26px
$font-size-large:        		1.375 !default;		//22px
$font-size-medium:       		1 !default;			//16px
$font-size-small:        		0.875 !default;		//14px
$font-size-xsmall:        		0.75 !default;		//12px

// Line height
//
$line-height-xlarge:      		$font-size-xlarge !default;
$line-height-large:      		$font-size-large !default;
$line-height-medium:     		$font-size-medium !default;
$line-height-small:      		$font-size-small !default;
$line-height-xsmall:     		$font-size-xsmall !default;

/*
 * Root Size
 */

:root {
	@include font($font-family, $regular-font-weight, $font-size-medium);
}
