.banco-estado-error-panel {
    width: 100%;
    height: auto;
    margin: 75px 0;
    padding: 90px 0;
    text-align: center;
    background: #fff;
    border-radius: 15px;
    border: 1px solid #bebebd;

    .beep-title {
        font-family: "Lato", sans-serif;
        font-weight: 600;
        font-size: 35px;
        color: #333;
        text-align: center;
        max-width: 1010px;
        margin: 0 auto 70px;
    }

    .beep-info {
        font-family: "Lato", sans-serif;
        font-size: 25px;
        font-style: italic;
        color: #333;
        margin: 0 auto 70px;
        width: 80%;
        max-width: 620px;
        text-align: center;
        line-height: 1.4;

        &.wider {
            width: 80%;
            max-width: 985px;
        }

        span {
            font-family: "Lato", sans-serif;
            font-size: 25px;
            font-style: italic;
            color: #ff8300;
        }
    }

    .beep-images {
        margin-bottom: 70px;

        img {
            height: 32px;

            &:last-child {
                margin-left: 15px;
            }
        }
    }

    .beep-link-container {
        font-family: "Lato", sans-serif;
        font-size: 21px;
        color: #1c355e;

        a {
            color: #1c355e;
            text-decoration: underline;

            &:hover {
                color: #ff8300;
            }
        }
    }
}

.banco-estado-placeholder {
    height: 75px;
    width: 100%;
}

/* MEDIA QUERIES */
@media #{$xsmall} {
    .banco-estado-error-panel {
        width: 95%;
        margin: 35px auto;
        padding: 35px 0;

        .beep-title {
            margin-bottom: 35px;
        }

        .beep-info {
            margin-bottom: 35px;
        }

        .beep-images {
            margin-bottom: 35px;
        }
    }
}
