/*
 * Margin
 */

// Margin
.margin-xl {
	margin: $margin-xlarge;
}

.margin-lg {
	margin: $margin-large;
}

.margin-md {
	margin: $margin-medium;
}

.margin-sm {
	margin: $margin-small;
}

.margin-xs {
	margin: $margin-xsmall;
}

// Margin Top
.margin-top-xl {
	margin-top: $margin-xlarge;
}

.margin-top-lg {
	margin-top: $margin-large;
}

.margin-top-md {
	margin-top: $margin-medium;
}

.margin-top-sm {
	margin-top: $margin-small;
}

.margin-top-xs {
	margin-top: $margin-xsmall;
}

// Margin Bottom
.margin-bottom-xl {
	margin-bottom: $margin-xlarge;
}

.margin-bottom-lg {
	margin-bottom: $margin-large;
}

.margin-bottom-md {
	margin-bottom: $margin-medium;
}

.margin-bottom-sm {
	margin-bottom: $margin-small;
}

.margin-bottom-xs {
	margin-bottom: $margin-xsmall;
}

// Margin Left
.margin-left-xl {
	margin-left: $margin-xlarge;
}

.margin-left-lg {
	margin-left: $margin-large;
}

.margin-left-md {
	margin-left: $margin-medium;
}

.margin-left-sm {
	margin-left: $margin-small;
}

.margin-left-xs {
	margin-left: $margin-xsmall;
}

// Margin Right
.margin-right-xl {
	margin-right: $margin-xlarge;
}

.margin-right-lg {
	margin-right: $margin-large;
}

.margin-right-md {
	margin-right: $margin-medium;
}

.margin-right-sm {
	margin-right: $margin-small;
}

.margin-right-xs {
	margin-right: $margin-xsmall;
}