$brand-primary: #0a396d;
$brand-secondary: #b92234;
$height: auto !default;
$white: #ffffff !default;
$font-color: $brand-primary !default;
$primary-color: $brand-primary !default;
$border-color: #dcdee2 !default;
$border-radius: 0 !default;

.ss-main {
	position: relative;
	display: inline-block;
	user-select: none;
	color: $font-color;
	width: 100%;

	.ss-single-selected {
		display: flex;
		cursor: pointer;
		width: 100%;
		height: $height;
		padding: 1rem 1rem;
		font-size: 1.2rem;
		border: 1px solid $border-color;
		border-radius: $border-radius;
		background-color: $white;
		outline: 0;
		box-sizing: border-box;
		transition: background-color 0.3s;

		&.ss-disabled {
			background-color: $border-color;
			cursor: not-allowed;
		}

		&.ss-open-above {
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}

		&.ss-open-below {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		.placeholder {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex: 1 1 100%;
			text-align: left;
			width: calc(100% - 30px);
			font-size: 1.2rem;
			line-height: 1.3rem;

			.code {
				@include font-size($font-size-small);
				display: inline-block;
				margin: 3px 0 0 10px;
			}

			* {
				display: flex;
				align-items: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
			}

			.ss-disabled {
				cursor: pointer;
				color: $border-color;
			}
		}

		.ss-arrow {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 0 1 auto;
			margin: -1px 4px 0 5px;

			span {
				border: solid $font-color;
				border-width: 0 2px 2px 0;
				display: inline-block;
				padding: 3px;
				transition: transform 0.2s, margin 0.2s;

				&.arrow-up {
					transform: rotate(-135deg);
					margin: 3px 0 0 0;
				}

				&.arrow-down {
					transform: rotate(45deg);
					margin: -3px 0 0 0;
				}
			}
		}
	}

	.ss-multi-selected {
		display: flex;
		flex-direction: row;
		cursor: pointer;
		min-height: $height;
		width: 100%;
		padding: 0 0 0 3px;
		border: 1px solid $border-color;
		border-radius: $border-radius;
		background-color: $white;
		outline: 0;
		box-sizing: border-box;
		transition: background-color 0.3s;

		&.ss-disabled {
			background-color: $border-color;
			cursor: not-allowed;

			.ss-values {
				.ss-disabled {
					color: $font-color;
				}

				.ss-value {
					.ss-value-delete {
						cursor: not-allowed;
					}
				}
			}
		}

		&.ss-open-above {
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}

		&.ss-open-below {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		.ss-values {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			flex: 1 1 100%;
			width: calc(100% - 30px);

			.ss-disabled {
				display: flex;
				padding: 4px 5px;
				margin: 2px 0px;
				line-height: 1em;
				align-items: center;
				width: 100%;
				color: $border-color;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			@keyframes scaleIn {
				0% {
					transform: scale(0);
					opacity: 0;
				}

				100% {
					transform: scale(1);
					opacity: 1;
				}
			}

			@keyframes scaleOut {
				0% {
					transform: scale(1);
					opacity: 1;
				}

				100% {
					transform: scale(0);
					opacity: 0;
				}
			}

			.ss-value {
				display: flex;
				user-select: none;
				align-items: center;
				font-size: 12px;
				padding: 3px 5px;
				margin: 3px 5px 3px 0px;
				color: $white;
				background-color: $primary-color;
				border-radius: $border-radius;
				animation-name: scaleIn;
				animation-duration: 0.2s;
				animation-timing-function: ease-out;
				animation-fill-mode: both;

				&.ss-out {
					animation-name: scaleOut;
					animation-duration: 0.2s;
					animation-timing-function: ease-out;
				}

				.ss-value-delete {
					margin: 0 0 0 5px;
					cursor: pointer;
				}
			}
		}

		.ss-add {
			display: flex;
			flex: 0 1 3px;
			margin: 9px 12px 0 5px;

			.ss-plus {
				display: flex;
				justify-content: center;
				align-items: center;
				background: $font-color;
				position: relative;
				height: 10px;
				width: 2px;
				transition: transform 0.2s;

				&:after {
					background: $font-color;
					content: "";
					position: absolute;
					height: 2px;
					width: 10px;
					left: -4px;
					top: 4px;
				}

				&.ss-cross {
					transform: rotate(45deg);
				}
			}
		}
	}

	.ss-content {
		position: absolute;
		width: 100%;
		margin: -1px 0 0 0;
		box-sizing: border-box;
		border-left: 5px solid $brand-primary;
		z-index: 1010;
		background-color: $white;
		transform-origin: center top;
		transition: transform 0.2s, opacity 0.2s;
		opacity: 0;
		transform: scaleY(0);

		&.ss-open {
			display: block;
			top: 0;
			opacity: 1;
			transform: scaleY(1);
			border-left: 5px solid $brand-primary;
		}

		.ss-search {
			padding: 8px 10px 8px 10px;
			display: none !important;

			input {
				display: none !important;
				font-size: inherit;
				width: 100%;
				height: 30px;
				padding: 5px 10px;
				border: 1px solid $border-color;
				border-radius: $border-radius;
				background-color: $white;
				outline: 0;
				box-sizing: border-box;

				&::placeholder {
					color: $brand-primary;
				}

				// &:focus {
				//   box-shadow: 0 0 5px $primary-color;
				// }
			}
		}

		.ss-list {
			max-height: 200px;
			overflow-x: hidden;
			overflow-y: auto;
			text-align: left;

			.ss-optgroup {
				.ss-optgroup-label {
					padding: 6px 10px 6px 10px;
					font-weight: bold;
				}

				.ss-option {
					padding: 10px 10px 10px 25px;
				}
			}

			.ss-option {
				display: flex;
				justify-content: space-between;
				padding: 10px 15px;
				cursor: pointer;
				user-select: none;
				font-size: 20px;

				.code {
					@include font-size($font-size-small);
				}

				// border-bottom: 1px solid $brand-primary;
				* {
					display: inline-block;
					vertical-align: middle;
				}

				&:hover,
				&.ss-highlighted {
					color: $white;
					background-color: $primary-color;
				}

				&.ss-disabled {
					cursor: default;
					color: $border-color;
					background-color: $white;
				}

				&.ss-hide {
					display: none;
				}
			}
		}
	}
}

.form-group {
	select.error[required] {
		& + .ss-main {
			.ss-single-selected {
				border: 1px solid $brand-error;
				color: $brand-error;
			}
		}
	}
}
