/*
 * Transitions
 */

@mixin transition($transition) {
	-webkit-transition: $transition;
	transition: $transition;
}

@mixin transitions($transition1, $transition2) {
	-webkit-transition: $transition1, $transition2;
	transition: $transition1, $transition2;
}

@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
	transition-delay: $transition-delay;
}

@mixin transition-property($transition-property) {
	-webkit-transition-property: $transition-property;
	transition-property: $transition-property;
}

@mixin transition-duration($transition-duration) {
	-webkit-transition-duration: $transition-duration;
	transition-duration: $transition-duration;
}

@mixin transition-transform($transition) {
	-webkit-transition: -webkit-transform $transition;
	transition: transform $transition;
}