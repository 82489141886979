.dg-promo-code {
	width: 350px;
	height: 55px;
	margin: 5px auto 0;

	input {
		@apply relative bg-white font-body font-semibold z-0 cursor-pointer;
		height: 55px;
		border: 1px solid $dg-gray-light;
		color: $dg-blue;
		font-size: 16px;

		&::placeholder {
			color: $dg-gray-light;
		}

		&.active {
			border-color: $dg-red;
		}

		&:disabled {
			@apply opacity-100 cursor-default;
			background: #f2f2f2;
		}

		&.error {
			background: #d8b5b9;
		}
	}
}

.promo-code-error-container {
	@apply absolute bg-white inset-y-0;
	left: 100%;
	width: 245px;
	padding: 30px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	z-index: -2;
	min-height: 70px;
}

/* MEDIA QUERIES */

@media #{$large} {
	.dg-promo-code {
		width: 310px;
	}
}

@media #{$medium} {
	.dg-promo-code {
		width: 260px;
	}
}

@media #{$small-down} {
	.promo-code-error-container {
		@apply rounded-b-lg w-auto inset-x-0;
		top: calc(100% - 22px);
		bottom: unset;
		box-shadow: $box-shadow-medium;
	}
}

@media #{$small} {
	.dg-promo-code {
		width: 260px;
	}
}

@media #{$xsmall} {
	.dg-promo-code {
		width: calc(50% - 20px);
		float: left;
		margin-left: 10px;

		input {
			height: 45px;
			color: $dg-blue;
		}
	}
}
