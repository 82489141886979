.admin-container {
	background: #fff;
	border-radius: 10px;
	padding: 20px;
	margin: 20px auto;
	box-shadow: $box-shadow-medium;
	min-height: calc(100vh - 200px);
	font-family: "Lato", sans-serif;
	font-size: 16px;
	max-width: 720px;
	color: #333;

	.main-buttons {
		text-align: right;
	}

	ul {
		li {
			margin-top: 10px;
			font-size: 14px;
			line-height: 1.2;

			span {
				font-weight: 600;
			}
		}
	}

	.btn-primary {
		height: 41px;
		padding: 0 25px;
		background: $brand-secondary;
		color: #fff;
		border-radius: 5px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: none;
		letter-spacing: normal;

		&:not(:disabled):hover {
			background: $brand-primary;
			color: #fff;
			border: none;
		}

		&.push-down {
			margin-top: 20px;
		}
	}

	.btn-secondary {
		height: 41px;
		padding: 0 25px;
		background: $brand-primary;
		color: #fff;
		border-radius: 5px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: none;
		letter-spacing: normal;

		&:not(:disabled):hover {
			background: $brand-secondary;
			color: #fff;
			border: none;
		}

		&.button-toggled {
			background-color: #59c3d9;
		}
	}

	.form-container {
		margin-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;

		button:not(:first-child) {
			margin-left: 25px;
		}
	}

	hr {
		margin: 20px 0;
		height: 1px;
		width: 100%;
		background: #ccc;

		&.shorter-divider {
			width: 80%;
			background: #eee;
			margin: 20px auto;
		}
	}

	.invalid {
		color: $brand-secondary;
	}

	.widget-alias,
	.page-alias {
		display: inline-block;
		position: relative;
		font-size: 16px;
		padding: 5px 30px 6px 10px;
		margin-bottom: 5px;
		background-color: #ccc;
		border-bottom: 1px solid #000;

		&:after {
			content: "";
			position: absolute;
			top: -5px;
			right: -5px;
			background: #fff;
			height: 10px;
			width: 10px;
			transform: rotate(45deg);
		}
	}

	.widget-culture-container,
	.page-culture-container {
		margin: 10px 0 25px;
	}

	.widget-culture,
	.page-culture {
		position: relative;

		&.valid {
			color: #83b245;

			&:after {
				position: absolute;
				top: 4px;
				right: -16px;
				content: "\2714";
				font-size: 12px;
			}
		}

		&.invalid {
			color: $brand-secondary;

			&:after {
				position: absolute;
				top: 4px;
				right: -16px;
				content: "\2718";
				font-size: 12px;
			}
		}
	}

	.page-btn-container {
		margin: 15px 0 10px;

		.btn-primary {
			height: 30px;
			padding: 0 15px;
		}
	}

	h1 {
		font-family: "Lato", sans-serif;
		font-weight: 600;
		font-size: 20px;
		padding: 0;
		margin: 0 0 15px;
	}

	label[for] {
		&.push-down {
			margin-top: 45px;
		}
	}

	.sj-save-warning {
		margin: 35px 0 0;
		color: $brand-secondary;
		font-size: 14px;
	}

	.preview-label {
		display: inline-block;
		padding: 5px 10px;
		background-color: #59c3d9;
		color: #fff;
		font-size: 14px;
		border-radius: 5px;
	}
}

.dpc-buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
	padding-top: 27px;

	> div {
		cursor: pointer;
		border-radius: 3px;
		padding: 5px;

		&:hover {
			background: $brand-secondary;
			color: #fff;
		}
	}
}

#admin_form {
	.invalid {
		background-color: rgba($brand-secondary, 0.3);
	}
}

/* MEDIA QUERIES */

@media #{$xsmall} {
	.admin-container {
		.main-buttons {
			text-align: left;

			.btn-primary,
			.btn-secondary {
				margin-top: 20px;
			}
		}

		form {
			.form-group {
				margin-bottom: 0;
			}
		}
	}
}
