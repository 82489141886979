/*!
 * Pico SCSS MicroFramework
 * Copyright 2016 DGTIAL Labs
 */

/*************************
 * Mixins
 ************************/

@import 'mixins/animations';
@import 'mixins/arrows';
@import 'mixins/box-shadow';
@import 'mixins/background';
@import 'mixins/border-radius';
@import 'mixins/decimal';
@import 'mixins/filters';
@import 'mixins/flexbox';
@import 'mixins/font';
@import 'mixins/mixins';
@import 'mixins/transitions';
@import 'mixins/transforms';

/*************************
 * Core Base
 ************************/

@import 'core/reset';
@import 'core/normalize';


/*************************
 * Base
 ************************/

@import 'base/base-bundle';

/*************************
 * Grid layout
 ************************/

@import 'grid/grid-bundle';


/*************************
 * Typography
 ************************/

@import 'typography/typography-bundle';


/*************************
 * Buttons
 ************************/

@import 'button/button-bundle';


/*************************
 * Table layout
 ************************/

@import 'table/table-bundle';

/*************************
 * Form
 ************************/

@import 'form/form-bundle';


/*************************
 * Image
 ************************/

 @import 'image/image-bundle';


/*************************
 * Callout
 ************************/

 @import 'callout/callout-bundle';


/*************************
 * Utilities
 ************************/

@import 'utilities/utilities-bundle';


/*************************
 * Device media queries
 ************************/

// @import 'media-devices/phones/galaxy';
// @import 'media-devices/phones/iphone';

// @import 'media-devices/tablets/ipad';
