$dg-gray-lightest: #e6e6e6;
$dg-gray-lighter: #cacaca;
$dg-gray-light: #8d8c8c;
$dg-red: #ae2535;
$dg-blue: #1b365d;
$dg-blue-light: #00aec7;
$spacing: 5px;

#carousel {
	@apply relative w-full;
	height: 585px;

	.field-error {
		display: none;
	}

	.promo-code-error-container {
		.field-error {
			@apply block;
		}
	}
}

.searchbox-container {
	@apply pointer-events-none absolute bottom-0 h-full;
	left: calc((100% - 1352px) / 2);
	width: 508px;
	z-index: 10000;
}

.searchbox {
	@apply absolute left-0 vertical-center;
	pointer-events: all;

	.disabled {
		@apply pointer-events-none opacity-100;
		background: #f2f2f2;
	}

	.loader-container {
		@apply inset-0 p-0;
		width: unset;
		height: unset;
		background: rgba(#fff, 0.5);
		z-index: 10000;
	}
}

.dg-tab-selector {
	height: 432px;
	width: 108px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;

	&.with-redemption-miles-and-promo-code {
		height: 482px;
	}

	ul {
		@apply block h-full w-full;
	}

	li {
		@apply block w-full;
		height: 25%;

		&:first-child {
			label {
				border-top-left-radius: 10px;
			}
		}

		&:last-child {
			label {
				border-bottom-left-radius: 10px;
			}
		}
	}

	&.with-insurance {
		li {
			height: 20%;
		}
	}

	label {
		@apply flex h-full w-full cursor-pointer flex-col items-center justify-center;
		transition: all 0.15s ease-in-out;
		background: $dg-gray-lighter;

		span {
			@apply font-body font-semibold;
			color: $dg-blue;
			font-size: 16px;
		}

		i {
			color: $dg-blue;
			font-size: 48px;
		}

		&:hover {
			transform: scale(1.1);
			transform-origin: center right;
			box-shadow: $box-shadow-medium;
		}

		&.active {
			background: $dg-gray-lightest;
			cursor: default;

			&:hover {
				transform: none;
				box-shadow: none;
			}
		}
	}
}

.dg-tab {
	@apply absolute top-0;
	left: 108px;
	width: 400px;
	height: 432px;
	background: $dg-gray-lightest;

	&.with-redemption-miles-and-promo-code {
		height: 482px;
	}
}

.transfer-credit {
	@apply absolute;
	bottom: 10px;
	right: 10px;
	font-size: 14px;
	color: $dg-blue;

	span {
		@apply font-semibold italic;
		font-size: 18px;
	}
}

.dg-insurance-header {
	@apply mb-6 flex w-full items-center justify-start;

	.dg-insurance-title-icon {
		@apply mr-2 text-be-cyan-4;

		font-size: 50px;
	}

	.dg-insurance-title {
		@apply font-medium leading-tight text-be-blue-2;

		font-size: 20px;
	}
}

.dg-insurance-text {
	@apply relative pl-8 text-be-gray-4;

	&:before {
		@apply absolute left-0 top-0 text-be-cyan-4;

		content: "\2714";
		font-size: 20px;
	}

	font-size: 18px;
}

.dg-insurance-btn-container {
	@apply flex w-full items-center justify-between;

	.dg-insurance-logo {
		height: 70px;
		width: 150px;
	}
}

.dg-btn-container {
	@apply flex w-full justify-center;
	margin: 11px 0;
	text-align: center;

	.dg-button {
		@apply inline-flex items-center justify-center border-none text-white;
		height: 45px;
		width: 202px;
		background: $dg-red;
		border-radius: 15px;
		font-size: 17px;

		&.dg-pax-btn {
			height: 40px;
			width: 80px;
			margin-top: 0;
			font-size: 14px;
		}

		span {
			@apply font-body leading-none;
			font-size: 17px;
			font-weight: 500;
			text-transform: none;
			letter-spacing: normal;
		}

		i {
			font-size: 16px;
			font-weight: 400;
			margin-right: 5px;
		}

		&:not(:disabled):hover {
			@apply border-none text-white;
			background: $dg-blue;
		}
	}

	.dg-rounded-primary-btn {
		span {
			@apply ml-1;
		}
	}
}

.dg-redemption-checkbox-container {
	@apply flex items-center;

	width: 350px;
	margin: 5px auto;
}

.dg-redemption-checkbox {
	input[type="checkbox"] {
		&:not(:checked) {
			+ label {
				// DEVNOTE: This is necessary to prevent pico animation
				&:before {
					display: none;
				}

				&:after {
					@apply absolute left-0 top-0 bg-white;
					content: "";
					width: 20px;
					height: 20px;
					border: 2px solid $dg-gray-lighter;
				}
			}
		}

		&:checked {
			+ label {
				&:before {
					display: none;
				}

				&:after {
					@apply absolute left-0 top-0 transform-none font-bold text-white;
					content: "\2714\fe0e";
					width: 20px;
					height: 20px;
					font-size: 12px;
					border: 1px solid $dg-blue-light;
					background-color: $dg-blue-light;
					text-align: center;
				}
			}
		}

		+ label {
			@apply font-body font-semibold;
			font-size: 16px;
			color: $dg-blue;

			&:before,
			&:after {
				@apply rounded-none;
			}
		}
	}
}

/* MEDIA QUERIES */

@media #{$large-down} {
	.searchbox-container {
		left: calc((100% - 1155px) / 2);
	}
}

@media #{$large} {
	.dg-tab {
		width: 340px;
	}

	.dg-redemption-checkbox-container {
		width: 310px;
	}
}

@media #{$medium} {
	.searchbox-container {
		left: 25px;
		width: 362px;

		.searchbox {
			.loader-container {
				.loader-line {
					left: 90px;
				}
			}

			.dg-tab-selector {
				width: 80px;

				label {
					span {
						font-size: 14px;
					}
				}
			}

			.dg-tab {
				left: 80px;
				width: 280px;
			}
		}
	}

	.dg-insurance-btn-container {
		.dg-insurance-logo {
			height: 50px;
			width: 120px;
		}
	}

	.dg-redemption-checkbox-container {
		width: 260px;
	}
}

@media #{$small} {
	.searchbox-container {
		left: 25px;
		width: 362px;

		.searchbox {
			.loader-container {
				.loader-line {
					left: 90px;
				}
			}

			.ps__rail-y {
				z-index: 11;
			}

			.dg-tab-selector {
				width: 80px;

				label {
					span {
						font-size: 14px;
					}
				}
			}

			.dg-tab {
				left: 80px;
				width: 280px;
			}
		}
	}

	.dg-insurance-btn-container {
		.dg-insurance-logo {
			height: 50px;
			width: 120px;
		}
	}

	.dg-redemption-checkbox-container {
		width: 260px;
	}
}

@media #{$xsmall} {
	#carousel {
		@apply relative;
		position: relative;
	}

	.searchbox-container {
		@apply relative mx-auto my-0 w-full transform-none;
		max-width: 100%;
		left: unset;
		height: unset;
		top: 0;

		.searchbox {
			@apply relative w-full transform-none;
			box-shadow: $box-shadow-medium;
			top: unset;
			left: unset;
			height: unset;

			.ps__rail-y {
				z-index: 11;
			}

			.loader-container {
				.loader-line {
					left: 130px;
				}
			}

			.dg-tab-selector {
				height: 90px;
				width: 100%;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				border-bottom-left-radius: 0;

				label {
					span {
						font-size: 14px;
					}
				}

				ul {
					@apply flex;

					li {
						height: 100%;
						width: 25%;

						&:first-child {
							label {
								border-top-left-radius: 10px;
							}
						}

						&:last-child {
							label {
								border-top-right-radius: 10px;
								border-bottom-left-radius: 0;
							}
						}
					}
				}
			}

			.dg-tab {
				@apply static w-full;
				padding: 10px 15px 10px;
				height: 400px;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;

				&.dg-flights {
					height: 320px;

					&.with-reload {
						height: 350px;
					}

					&.with-redemption-miles-and-promo-code {
						&:not(.collapsed) {
							height: 360px;

							&.with-reload {
								height: 390px;
							}
						}
					}

					&.with-redemption-miles {
						&:not(.collapsed) {
							height: 360px;

							&.with-reload {
								height: 400px;
							}
						}
					}

					&.collapsed {
						height: unset;
					}
				}

				&.dg-insurance {
					height: unset;
				}

				&.dg-cars {
					height: unset;
				}

				&.dg-transfers {
					@apply relative;
					top: unset;
					left: unset;
				}
			}

			.dg-mobile-header {
				@apply flex items-center justify-between font-body font-semibold;
				height: 40px;
				padding: 0 20px;
				color: $dg-blue;
				font-size: 20px;
				background: $dg-gray-lightest;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;

				span {
					&:last-child {
						@apply cursor-pointer;
						font-size: 30px;
					}
				}
			}
		}
	}

	.transfer-credit {
		bottom: unset;
		top: 24px;
	}

	.dg-redemption-checkbox-container {
		width: calc(100% - 30px);
	}
}
