.pcra-login-info-modal {
	@apply p-0 leading-normal;
	width: 413px;
	height: 480px;
	max-width: 95%;
	max-height: 95%;
}

.pcra-login-modal-header {
	@apply text-white flex items-center justify-center relative px-8 font-black;
	background-color: #2c3438;
	line-height: 33px;
	min-height: 72px;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	font-size: 22px;
	height: 72px;
}

.pcra-login-info-content {
	@apply flex flex-col justify-between items-center text-be-blue font-medium;

	text-align: justify;
	padding: 20px 20px 4px;
	height: 336px;

	p {
		@apply mb-4 whitespace-normal font-body;
		line-height: 1.6rem;
		font-size: 16px;
	}
}

.pcra-modal-footer {
	@apply flex items-center justify-center p-6;

	height: 72px;
	border-top: 2px solid #d9d9d9;
}

//MEDIA QUERIES
@media #{$small} {
	.pcra-login-info-modal {
		height: 460px;
	}

	.pcra-modal-footer,
	.pcra-login-modal-header {
		min-height: 0;
		height: 62px;
	}
}

@media #{$xsmall} {
	.pcra-login-info-modal {
		width: 335px;
		height: 480px;
	}

	.pcra-login-info-content {
		padding: 15px 15px 4px;
		height: 356px;
	}

	.pcra-modal-footer,
	.pcra-login-modal-header {
		min-height: 0;
		height: 62px;
	}
}
