$footer-bg:             #fff;
$common-gap:            20px;
$small-gap:             10px;

.modal-open {
    .js-footer {
        z-index: -2;
    }
}

.js-footer {
    width: 100%;
    background: $footer-bg;
    font-size: 1rem;
    text-align: left;
    position: relative;
    margin-top: $common-gap;


    .footer-container {
        position: relative;
        margin: 0 auto 20px auto;
        max-width: 1155px;
    }

    .footer-end {
        position: relative;
        padding: 20px 45px 20px 345px;
        border-top: 8px solid #903235;
        width: 100%;

        .footer-end-content {
            max-width: 1155px;
        }
    }

    &.full-width {
        .footer-container {
            margin: 20px auto;
        }

        .footer-end {
            padding: 20px 0;

            .footer-end-content {
                margin: 0 auto;
                max-width: 1155px;
            }
        }

    }
}

.payment-images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .footer-payment-icons {
        margin: 10px;
        max-height: 40px;
        max-width: 100%;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.footer-logo {
    text-align: right;

    img {
        max-height: 2.125rem;
        max-width: 10.875rem;
    }
}

.footer-copyright {
    display: flex;
    height: 2.125rem;
    align-items: center;
    color: #1d4071;
}

.footer-title {
    color: #1d4071;
    font-size: 18px;
    margin: 25px 0 10px;
    display: block;

    &.bold {
        font-weight: 900;
    }

    &.push-down {
        margin-top: 45px;
    }

    &.footer-section-opener {
        position: relative;
        user-select: none;
        line-height: 1.2;
        height: unset;
        font-size: 18px;
        padding-left: 0;
        display: block;
        cursor: default;

        &:before {
            display: none;
        }

        .opened {
            display: none;
        }

        .closed {
            display: none;
        }
    }
}

label {
    &.footer-title {
        color: #1d4071;
        font-size: 18px;
        margin: 25px 0 10px;
        display: block;

        &.bold {
            font-weight: 900;
        }

        &.push-down {
            margin-top: 45px;
        }

        &.footer-section-opener {
            position: relative;
            user-select: none;
            line-height: 1.2;
            height: unset;
            font-size: 18px;
            padding-left: 0;
            display: block;
            cursor: default;

            &:before {
                display: none;
            }

            .opened {
                display: none;
            }

            .closed {
                display: none;
            }
        }
    }
}

.footer-links {
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
    line-height: 1.45;

    a {
        display: inline;
        color: #70706f;

        span {
            display: inline-block;

            &:after {
                display: block;
                content: '';
                border-bottom: solid 1px #E9B22A;
                transform: scaleX(0);
                transition: transform 250ms ease-in-out;
                transform-origin: 0% 50%;
                margin-top: -2px;
            }
        }

        &:hover {
            color: #70706f;

            span {
                &:after {
                    transform: scaleX(1);
                }
            }
        }
    }
}

.social-icons {
    a {
        display: inline-block;
        text-align: center;
        padding: 6px 5px;
        color: #1d4071;
        font-size: 40px;
        cursor: pointer;

        &:hover {
            color: #903235;
            cursor: pointer;
        }

        i {
            cursor: pointer;

            &:hover {
                color: #903235;
                cursor: pointer;
            }
        }
    }
}

.footer-medal {
    padding: 30px;

    img {
        max-width: 120px;
    }
}

/* MEDIA QUERIES */

@media #{$large} {
    .js-footer {
        .footer-container {
            margin: 45px auto 45px;
        }
    }
}

@media #{$medium} {
    .js-footer {
        .footer-container {
            margin: 45px 20px 45px;
        }

        &.full-width {
            .footer-container {
                margin: 20px 25px;
            }

            .footer-end {
                .footer-end-content {
                    margin: 0 25px;
                }
            }
        }
    }
}

@media #{$small-down} {
    .js-footer {
        .footer-container {
            margin: 45px 25px 45px 25px;
        }

        &.full-width {
            .footer-container {
                margin: 20px 25px;
            }

            .footer-end {
                .footer-end-content {
                    margin: 0 25px;
                }
            }
        }
    }
}

@media #{$xsmall} {
    .js-footer {
        .footer-title {
            text-align: center;
        }

        label {
            &.footer-title {
                margin: 3px 0;

                &.footer-section-opener {
                    text-align: left;
                    cursor: pointer;

                    i {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                    }
                }
            }
        }

        .section-opener[type=checkbox]:checked {
            ~label {
                .opened {
                    display: block;
                }

                .closed {
                    display: none;
                }
            }

            ~.footer-links {
                max-height: 300px;
            }
        }

        .section-opener[type=checkbox]:not(:checked) {
            ~label {
                .opened {
                    display: none;
                }

                .closed {
                    display: block;
                }
            }

            ~.footer-links {
                max-height: 0;
            }

        }

        .social-icons {
            margin-bottom: 20px;
        }

        .footer-end {
            padding: 20px;

            .footer-copyright {
                justify-content: center;
                margin-bottom: 20px;
            }

            .footer-logo {
                text-align: center;
            }
        }
    }
}
