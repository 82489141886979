@tailwind base;

@tailwind components;

@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900");

@font-face {
	font-family: ClanOT-Bold;
	font-weight: bold;
	src: url("./fonts/ClanOT-Bold.otf") format("opentype"), url("./fonts/ClanOT-Bold.woff") format("woff");
}

@font-face {
	font-family: ClanOT-News;
	font-weight: normal;
	src: url("./fonts/ClanOT-News.otf") format("opentype"), url("./fonts/ClanOT-News.woff") format("woff");
}

@font-face {
	font-family: ClanOT-Medium;
	font-weight: normal;
	src: url("./fonts/ClanOT-Medium.otf") format("opentype"), url("./fonts/ClanOT-Medium.woff") format("woff");
}

@font-face {
	font-family: ClanOT-Black;
	font-weight: normal;
	src: url("./fonts/ClanOT-Black.otf") format("opentype"), url("./fonts/ClanOT-Black.woff") format("woff");
}

@font-face {
	font-family: Gotham-Bold;
	font-weight: normal;
	src: url("./fonts/Gotham-Bold.otf") format("opentype");
}

@font-face {
	font-family: Gotham-Book;
	font-weight: normal;
	src: url("./fonts/Gotham-Book.otf") format("opentype");
}

@import "pico/pico";

@import "icons";
@import "icons-be2";
@import "icons-new-home";
@import "content";
@import "footer";
@import "header";
@import "plugins";
@import "media-queries";
@import "admin";
@import "banco-estado-errors";
@import "banco-estado-bar";
@import "../../app/components/searchbox/searchbox";
@import "../../app/components/searchbox/route-selector/route-selector";
@import "../../app/components/searchbox/date-selector/date-selector";
@import "../../app/components/searchbox/trip-type-selector/trip-type-selector";
@import "../../app/components/searchbox/pax-selector/pax-selector";
@import "../../app/components/searchbox/promo-code-box/promo-code-box";
@import "../../app/components/searchbox/opening-pane/opening-pane";
@import "../../app/components/pcra-login-info-modal/peru-compra-login-info-modal";
