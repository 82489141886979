.dg-new-modal-close {
	@apply absolute flex items-center justify-center leading-none rounded-full cursor-pointer font-semibold text-white;
	z-index: 1;
	width: 32px;
	height: 32px;
	right: 12px;
	top: 12px;
	padding-bottom: 2px;
	padding-left: 1px;
	background-color: rgba(51, 84, 109, 0.7);
	font-size: 26px;
}

.dg-new-modal-banner {
	@apply relative w-full bg-center bg-cover bg-no-repeat;
	height: 250px;

	&.checkin {
		background-image: url("../images/modals/check-in-modal-banner.png");
	}

	&.admin {
		background-image: url("../images/modals/admin-modal-banner.png");
	}
}

.dg-new-modal-info-bar {
	@apply flex items-center justify-center text-white font-body;
	padding: 15px;
	background: #00a2bb;
	font-size: 20px;
	text-align: center;

	&.dg-smaller-info-bar {
		@apply relative;
		font-size: 18px;
	}
}

.dg-new-modal-content {
	@apply fixed overflow-hidden all-center rounded-2xl bg-white;
	z-index: 100001;
	width: 805px;
	max-width: 95%;
}

.dg-new-large-modal-title {
	@apply block font-emphasis;
	max-width: 310px;
	color: $header-secondary-color;
	font-size: 40px;
	line-height: 38px;
	text-align: center;

	&.wider {
		max-width: 370px;
	}
}

.dg-caption {
	@apply flex flex-col;
	color: $header-secondary-color;

	span {
		font-size: 30px;
		line-height: 28px;
		text-align: center;

		&:nth-child(2) {
			@apply font-very-heavy leading-none;
			font-size: 40px;
			margin-top: 2px;
		}
	}
}

.dg-new-modal {
	@apply fixed top-0 left-0 w-full h-full;
	background: rgba($brand-primary, 0.4);
	z-index: 100000;

	.dg-modal-form {
		margin-top: 30px;

		.form-group {
			margin-bottom: 20px;
		}

		.dg-rounded-primary-btn {
			margin: 0 0 30px 10px;
		}

		&:after {
			@apply absolute;
			content: "";
			background: $header-secondary-color;
			bottom: 10%;
			left: -2%;
			width: 1px;
			height: 90%;
		}
	}

	.checkin-tooltip {
		.arrow-box {
			color: #00416e;
			padding-left: 60px;
			width: 340px;
		}

		&:after {
			@apply absolute font-extralight flex items-center justify-center border border-solid rounded-full vertical-center;
			content: "!";
			border-color: #00416e;
			color: #00416e;
			font-size: 20px;
			height: 30px;
			left: 15px;
			width: 30px;
			z-index: 10000;
		}
	}
}

.dg-booking-error {
	color: $brand-secondary;
	margin-bottom: 10px;
	font-size: 14px;
	text-align: center;
}

/* MEDIA QUERIES */

@media #{$xsmall} {
	.dg-new-modal-close {
		width: 28px;
		height: 28px;
		right: 12px;
		top: 12px;
		padding-bottom: 2px;
		padding-left: 1px;
		font-size: 22px;
	}

	.dg-new-modal-banner {
		@apply h-0;
		padding-top: 30%;

		&.checkin {
			background-image: url("../images/modals/check-in-modal-banner-small.png");
		}

		&.admin {
			background-image: url("../images/modals/admin-modal-banner-small.png");
		}
	}

	.dg-new-modal-info-bar {
		font-size: 15px;

		&.dg-smaller-info-bar {
			font-size: 13px;
			margin-bottom: 15px;
		}
	}

	.dg-new-modal-content {
		width: 95%;
	}

	.dg-new-large-modal-title {
		font-size: 30px;
		line-height: 28px;
		max-width: unset;
		margin: 15px 0;

		&.wider {
			max-width: unset;
		}
	}

	.dg-caption {
		@apply flex-col-reverse;
		margin-bottom: 15px;

		span {
			font-size: 28px;
			line-height: 26px;

			&:nth-child(2) {
				font-size: 30px;
				margin-top: 15px;
				margin-bottom: 5px;
			}
		}
	}

	.dg-new-modal {
		.dg-modal-form {
			@apply mt-0;

			.dg-rounded-primary-btn {
				@apply mt-0 mx-auto;
				margin-bottom: 15px;
			}

			&:after {
				@apply w-0 h-0;
			}
		}

		.checkin-tooltip {
			.arrow-box {
				width: 320px;
				padding-left: 60px;
			}

			&:after {
				width: 30px;
				height: 30px;
				left: 15px;
				font-size: 20px;
			}
		}
	}

	.dg-enhanced-input {
		label[for] {
			font-size: 13px;
		}
	}
}
