.with-reload {
	.dg-route-selector {
		margin-top: 15px;
	}
}

.dg-route-selector {
	@apply relative;
	width: 350px;
	height: 111px;
	margin: 30px auto 0;

	input {
		@apply relative z-0 cursor-pointer bg-white font-body font-semibold;
		height: 55px;
		border: 1px solid $dg-gray-light;
		color: $dg-blue;
		font-size: 16px;

		&.in-focus {
			border-color: $dg-red;
			z-index: 1;

			+ .route-icon {
				transform: rotate(0);
				color: $dg-red;
			}

			~ .dg-divider-arrow-route {
				border-bottom-color: $dg-red;
				border-right-color: $dg-red;
				background: #fff !important;
			}
		}

		&:first-child {
			top: 1px;
		}

		&::placeholder {
			color: $dg-gray-light;
		}

		&.active {
			border-color: $dg-red;
		}

		&:disabled {
			@apply cursor-default opacity-100;
			background: #f2f2f2;
		}

		&.error {
			background: #d8b5b9;

			~ .dg-divider-arrow-route {
				background: #d8b5b9;
			}
		}
	}

	.route-icon {
		@apply absolute;
		right: 15px;
		color: $dg-gray-light;
		font-size: 8px;
		transition: all 0.15s ease-in-out;
		transform: rotate(90deg);
		z-index: 2;

		&.route-icon-departure {
			@apply pointer-events-none;
			top: 24px;
		}

		&.route-icon-destination {
			@apply pointer-events-none;
			bottom: 24px;
		}
	}
}

.dg-divider-arrow-route {
	@apply absolute bg-white;
	top: 50%;
	left: 50%;
	transform: translate(-3px, -5px) rotate(45deg);
	width: 10px;
	height: 10px;
	border-bottom: 1px solid $dg-gray-light;
	border-right: 1px solid $dg-gray-light;
	z-index: 2;
}

.dg-location-selector {
	@apply absolute bottom-0 top-0 bg-white;
	left: 100%;
	width: 245px;
	margin-left: -245px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: all 0.25s ease-in-out;
	z-index: -1;

	&.open {
		@apply ml-0;
	}

	header {
		@apply flex items-center justify-between;
		padding-bottom: 20px;
		border-bottom: 2px solid $dg-gray-lighter;

		.country-title {
			margin-right: 15px;

			h1 {
				@apply font-body font-black;
				font-size: 12px;
				color: $dg-blue;
			}

			h2 {
				@apply whitespace-normal font-body;
				font-size: 11px;
				color: $dg-blue;
			}
		}

		i {
			color: $dg-blue;

			&:first-child {
				font-size: 38px;
				margin-right: 10px;
			}

			&:last-child {
				font-size: 15px;
			}
		}
	}
}

.dg-typing-results {
	@apply absolute inset-0 overflow-hidden;
	padding: 10px 20px 10px 10px;
}

.dg-city-selector-label {
	@apply block font-body font-semibold;
	font-size: 17px;
	color: $dg-red;
	margin: 20px 0 10px 25px;
}

.dg-region-label {
	@apply pointer-events-none font-body font-semibold;
	font-size: 17px;
	color: $dg-red;
	padding: 8px 16px;
}

.dg-typing-region-label {
	@apply pointer-events-none font-body font-semibold;
	font-size: 17px;
	color: $dg-red;
	padding: 12px 0 0 30px;
}

.dg-city-selector-list {
	@apply absolute bottom-0 left-0 right-0 block overflow-hidden;
	padding: 0 0 0 10px;
	height: 390px;
	border-bottom-right-radius: 10px;

	&.with-redemption-miles-and-promo-code {
		height: 440px;
	}
}

.dg-typing-results-list-item {
	@apply relative block cursor-pointer font-semibold;
	color: $dg-blue;
	font-size: 17px;
	transition: all 0.25s ease-in-out;
	padding: 12px 58px 11px 30px;

	.dg-station-code {
		@apply pointer-events-none;
		color: $dg-blue;
		font-weight: 400;
		transition: all 0.25s ease-in-out;
	}

	&:hover {
		@apply text-white;
		background: $dg-red;

		.dg-station-code {
			@apply text-white;
		}

		.dg-station-new {
			@apply bg-white;
			color: $dg-red;
		}
	}
}

.dg-typing-result-item {
	@apply block pl-0;

	&:hover {
		@apply bg-transparent;
	}
}

.dg-country-selector-list {
	@apply relative block overflow-hidden;
	height: 300px;

	&.with-redemption-miles-and-promo-code {
		height: 350px;
	}
}

.dg-country-selector-list-item {
	@apply flex cursor-pointer items-center justify-between;
	padding: 12px 17px 11px 15px;
	transition: background-color 0.25s ease-in-out;

	.dg-country-name {
		@apply pointer-events-none font-body font-semibold;
		color: $dg-blue;
		transition: color 0.25s ease-in-out;
		font-size: 17px;
	}

	i {
		@apply pointer-events-none;
		color: $dg-blue;
		font-size: 22px;
		transition: color 0.25s ease-in-out;
	}

	&:hover,
	&.active {
		background: $dg-red;

		.dg-country-name,
		i {
			@apply text-white;
		}
	}
}

.dg-city-selector-list-item {
	@apply relative block cursor-pointer font-body font-semibold;
	padding: 12px 58px 11px 30px;
	margin-right: 15px;
	color: $dg-blue;
	transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
	font-size: 17px;

	&:hover,
	&.active {
		@apply text-white;
		background: $dg-red;

		.dg-station-new {
			@apply bg-white;
			color: $dg-red;
		}
	}

	.dg-station-code {
		@apply pointer-events-none;
		font-weight: 400;
		font-size: 15px;
	}
}

.dg-station-new {
	@apply absolute flex items-center justify-center font-body font-semibold capitalize text-white;
	top: 11px;
	right: 8px;
	background: $dg-red;
	font-size: 11px;
	transition: all 0.25s ease-in-out;
	width: 42px;
	height: 20px;
	border-radius: 10px;
	line-height: 1.3;
}

.dg-country-selector-label {
	@apply block font-body font-semibold;
	color: $dg-red;
	margin: 20px 0 10px 15px;
	font-size: 18px;
}

/* MEDIA QUERIES */

@media #{$large} {
	.dg-route-selector {
		width: 310px;
	}
}

@media #{$medium} {
	.dg-route-selector {
		width: 260px;
	}

	.dg-location-selector {
		width: 200px;
	}

	.dg-country-selector-list-item {
		.dg-country-name {
			font-size: 15px;
		}
	}

	.dg-city-selector-list-item {
		font-size: 15px;
	}
}

@media #{$small} {
	.dg-route-selector {
		width: 260px;
	}

	.dg-location-selector {
		@apply right-auto h-auto overflow-hidden;
		left: 280px;
		margin-left: -280px;
		max-width: 280px;
		width: 355px;
		z-index: 10;
		border-top-left-radius: 10px;
		transition: all 0.25s ease-in-out;
		z-index: -1;

		&.open {
			margin-left: -10px;
			max-width: 365px;
		}

		.dg-mobile-search {
			@apply absolute block border-none;
			top: 30px;
			left: 20px;
			right: unset;
			width: 290px;
			background: #eee;
			border-radius: 20px;
			padding-left: 40px;
			color: $dg-blue;

			&:focus {
				border-color: #a6a6a6;
			}

			&::placeholder {
				color: $dg-blue;
			}
		}

		.close-tablet-selector {
			@apply absolute cursor-pointer font-body font-bold;
			top: 32px;
			right: 15px;
			font-size: 28px;
			color: $dg-blue;
		}

		> i {
			@apply absolute;
			color: $dg-blue;
			font-size: 16px;
			top: 43px;
			left: 30px;
		}

		.dg-typing-opener {
			font-size: 16px;
			font-weight: 600;
			padding: 8px 16px;
		}

		.dg-region-label {
			padding: 8px 16px;
			color: $dg-blue;
			font-weight: 600;
		}

		.dg-typing-region-label {
			font-weight: 600;
			padding-left: 16px;
			color: $dg-blue;
		}

		.dg-typing-results {
			@apply p-0;
			top: 80px;

			label[for] {
				@apply m-0 font-semibold;
				padding: 9px 16px;
				color: $dg-blue;
				font-size: 16px;
			}
		}

		.dg-city-selector-list {
			@apply h-auto bg-white pl-0;
			top: 90px;
			left: 10px;
		}

		.dg-typing-results-list-item {
			@apply block;
			font-weight: 400;
		}

		.dg-typing-result-item {
			@apply p-0;
			color: $dg-blue;
		}

		.dg-mobile-list {
			display: none;

			.dg-station-code {
				@apply pointer-events-none;
			}
		}

		.dg-mobile-list-item {
			@apply relative mr-0 block cursor-pointer font-body;
			padding: 8px 67px 8px 32px;
			font-weight: 400;
			color: $dg-blue;
			transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
			font-size: 17px;

			&.collapsed {
				@apply p-0;

				> label {
					padding-left: 30px;
				}

				li {
					padding: 8px 67px 8px 52px;
				}
			}

			&.active {
				@apply text-white;
				background: $dg-red;

				.dg-station-new {
					@apply bg-white;
					color: $dg-red;
				}
			}

			.dg-station-new {
				top: 7px;
				right: 20px;
			}

			.dg-typing-opener {
				@apply relative cursor-pointer font-body;
				font-weight: 400;
				color: $dg-blue;
				font-size: 17px;
				z-index: 10;

				&:after {
					right: 20px !important;
				}
			}

			.dg-mobile-list {
				@apply relative;

				&:before {
					@apply absolute z-0 block;
					content: "";
					top: -36px;
					height: 36px;
					left: -40px;
					right: -70px;
					background: $dg-red;
				}
			}
		}

		input:checked {
			~ .dg-mobile-list {
				@apply block;
			}
		}

		.dg-city-selector-list-item {
			@apply p-0;

			&:hover {
				@apply bg-transparent;
				color: $dg-blue;
			}
		}

		.dg-btn-container {
			margin: 40px 0;
		}

		// Source: https://css-tricks.com/clearfix-a-lesson-in-web-development-evolution/
		.dg-clear-on-mobile {
			&:before,
			&:after {
				content: " ";
				display: table;
			}

			&:after {
				clear: both;
			}
		}
	}
}

@media #{$xsmall} {
	.dg-route-selector {
		@apply mt-0 select-none;
		width: calc(100% - 30px);
		height: 91px;

		input {
			height: 45px;
			color: $dg-blue;
		}

		.route-icon {
			&.route-icon-departure {
				top: 19px;
			}

			&.route-icon-destination {
				bottom: 19px;
			}
		}
	}

	.dg-location-selector {
		@apply absolute inset-0 h-auto w-auto;
		display: none;
		z-index: 10;
		border-top-left-radius: 10px;

		&.open {
			@apply block;
		}

		.dg-mobile-search {
			padding-left: 40px;
			color: $dg-blue;

			&:focus {
				border-color: #a6a6a6;
			}

			&::placeholder {
				@apply italic;
			}
		}

		> i {
			@apply absolute;
			color: $dg-blue;
			font-size: 16px;
			top: 53px;
			left: 10px;
		}

		.dg-typing-opener {
			font-size: 18px;
			font-weight: 600;
			padding: 8px 16px;
		}

		.dg-region-label {
			padding: 8px 16px;
			color: $dg-blue;
			font-weight: 600;
		}

		.dg-typing-region-label {
			font-weight: 600;
			padding-left: 16px;
			color: $dg-blue;
		}

		.dg-typing-results {
			@apply p-0;
			top: 80px;

			label[for] {
				@apply m-0 font-semibold;
				padding: 9px 16px;
				color: $dg-blue;
				font-size: 18px;
			}
		}

		.dg-city-selector-list {
			@apply h-auto bg-white pl-0;
			top: 81px;
		}

		.dg-typing-results-list-item {
			@apply block;
			font-weight: 400;
		}

		.dg-typing-result-item {
			@apply p-0;
			color: $dg-blue;
		}

		.dg-mobile-list {
			display: none;

			.dg-station-code {
				@apply pointer-events-none;
			}
		}

		.dg-mobile-list-item {
			@apply relative mr-0 block cursor-pointer font-body;
			padding: 8px 67px 8px 32px;
			font-weight: 400;
			color: $dg-blue;
			transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
			font-size: 17px;

			&.collapsed {
				@apply p-0;

				> label {
					padding-left: 30px;
				}

				li {
					padding: 8px 67px 8px 52px;
				}
			}

			&.active {
				@apply text-white;
				background: $dg-red;

				.dg-station-new {
					@apply bg-white;
					color: $dg-red;
				}
			}

			.dg-station-new {
				top: 7px;
				right: 20px;
			}

			.dg-typing-opener {
				@apply relative cursor-pointer font-body;
				font-weight: 400;
				color: $dg-blue;
				font-size: 17px;
				z-index: 10;

				&:after {
					right: 20px !important;
				}
			}

			.dg-mobile-list {
				@apply relative;

				&:before {
					@apply absolute z-0 block;
					content: "";
					top: -36px;
					height: 36px;
					left: -40px;
					right: -70px;
					background: $dg-red;
				}
			}
		}

		input:checked {
			~ .dg-mobile-list {
				@apply block;
			}
		}

		.dg-city-selector-list-item {
			@apply p-0;

			&:hover {
				@apply bg-transparent;
				color: $dg-blue;
			}
		}

		.dg-btn-container {
			@apply w-full;
			margin: 40px 0;
		}

		// Source: https://css-tricks.com/clearfix-a-lesson-in-web-development-evolution/
		.dg-clear-on-mobile {
			&:before,
			&:after {
				content: " ";
				display: table;
			}

			&:after {
				clear: both;
			}
		}
	}
}
