@import "../../../../assets/css/pico/base/breakpoints";
@import "../../../../assets/css/pico/base/media-queries";

.dg-calendar {
	@apply absolute bottom-0 overflow-hidden bg-white;
	left: 410px;
	height: 432px;
	width: 865px;
	max-width: 495px;
	z-index: -2;
	margin-left: -508px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: margin-left 0.25s ease-in-out;

	&.open {
		margin-left: -10px;
		height: 432px;
		max-width: 865px;
		z-index: -1;

		&.with-redemption-miles-and-promo-code {
			height: 482px;
		}
	}
}

.dg-dp-months-container {
	@apply relative flex h-full w-full;
}

.dg-dp-legend {
	@apply relative max-w-full;
	visibility: hidden;
	height: 0px;
	padding: 12px 25px 0 25px;
	color: #1e395d;
	font-size: 14px;
}

.dg-dp-legend-header {
	@apply mb-4 flex flex-row px-2;

	p {
		@apply m-0 font-bold;
		font-size: 17pt;
	}

	i {
		@apply mr-4;
		font-size: 20pt;

		&.jsh-plane {
			&.return {
				transform: rotate(45deg);
			}
		}
	}
}

.dg-dp-month {
	@apply relative w-1/2;
	height: 432px;
	padding: 10px 25px 12px 25px;

	&:last-child {
		@apply border-none;
	}
}

.separator-line {
	border-right: 1px solid #e0e2e5;
	position: absolute;
	right: 0;
	top: 10%;
	bottom: 10%;
}

.dg-dp-disabled {
	@apply cursor-default opacity-25;
}

.dg-dp-square {
	@apply relative inline-flex w-full items-center justify-center;
	font-size: 13px;
	height: 53px;
	margin: 0;

	&.dg-dp-navigation {
		@apply cursor-pointer text-2xl text-dp-blue;

		&:not(.dg-dp-disabled):hover {
			@apply bg-dp-blue text-white;
		}
	}

	&.dg-dp-month-back {
		&:after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}

	&.dg-dp-month-forward {
		&:after {
			transform: translate(-50%, -50%) rotate(225deg);
		}
	}

	&.dg-dp-month-back,
	&.dg-dp-month-forward {
		@apply relative my-0 inline-block;

		&:after {
			@apply absolute border-b border-l border-r-0 border-t-0 border-solid border-dp-red;
			content: "";
			width: 10px;
			height: 10px;
			top: 50%;
			left: 50%;
		}

		&.dg-dp-disabled,
		&:not(.dg-dp-disabled) {
			&:hover {
				@apply bg-transparent;

				&:after {
					@apply border-dp-blue;
				}
			}
		}
	}

	&.dg-dp-date {
		@apply cursor-pointer flex-col items-center justify-center text-dp-blue text-dp-blue;
		border-radius: 5px;

		&.lowest-price {
			color: #00aec7;
		}

		span {
			@apply mt-2 font-normal;
			font-size: 10px;
		}

		&.dg-dp-today {
			@apply border-dp-red font-bold text-dp-red;
		}

		&.dg-dp-selected {
			@apply relative overflow-hidden bg-dp-red font-bold text-white;

			&.dg-dp-range-start {
				@apply rounded-r-none;

				&:before {
					@apply absolute inset-y-0 left-0;
					content: "";
					width: 0;
					border-top: 25px solid transparent;
					border-bottom: 25px solid transparent;
					border-left: 6px solid #dd7f8c;
				}
			}

			&.dg-dp-range-end {
				@apply rounded-l-none;

				&:after {
					@apply absolute inset-y-0 right-0;
					content: "";
					left: unset;
					width: 0;
					border-top: 25px solid transparent;
					border-bottom: 25px solid transparent;
					border-right: 6px solid #dd7f8c;
					border-left: none;
				}
			}
		}

		&.dg-dp-in-range {
			@apply rounded-none bg-dp-pink text-white opacity-100;

			span {
				@apply opacity-0;
			}
		}

		&:nth-child(6),
		&:nth-child(7) {
			@apply font-bold;
		}

		&:not(.dg-dp-disabled):hover {
			@apply bg-dp-red text-white;

			&.dg-dp-hover-dg-dp-range-end {
				@apply rounded-l-none;

				&:after {
					@apply absolute inset-y-0 right-0;
					content: "";
					left: unset;
					width: 0;
					border-top: 25px solid transparent;
					border-bottom: 25px solid transparent;
					border-right: 6px solid #dd7f8c;
					border-left: none;
				}
			}
		}
	}

	&.dg-dp-col-header {
		@apply my-2 flex items-center justify-center font-bold uppercase text-dp-blue;
		height: 21px;
		font-size: 12px;
	}
}

.dg-dp-unit-nav {
	@apply inline-flex items-center px-2 font-body capitalize text-dp-red;
	font-size: 16px;
	height: 30px;
}

/* MEDIA QUERIES */

@media #{$large} {
	.dg-calendar {
		left: 350px;
		max-width: 438px;
		margin-left: -448px;
		transition: margin-left 0.25s ease-in-out;

		&.open {
			max-width: 715px;
		}
	}

	.dg-dp-month {
		padding: 10px 12px 12px 12px;
	}

	.dg-dp-square {
		font-size: 12px;

		&.dg-dp-date {
			span {
				font-size: 10px;
			}
		}
	}
}

@media #{$medium} {
	.dg-calendar {
		left: 280px;
		max-width: 348px;
		margin-left: -348px;
		transition: margin-left 0.25s ease-in-out;

		&.open {
			max-width: 630px;
			margin-left: -0.8px;
		}
	}

	.dg-dp-month {
		padding: 8px 10px 10px 10px;
	}

	.dg-dp-square {
		font-size: 11px;

		&.dg-dp-date {
			span {
				font-size: 9px;
			}
		}
	}
}

@media #{$small} {
	.dg-calendar {
		left: 280px;
		max-width: 348px;
		margin-left: -348px;
		transition: margin-left 0.25s ease-in-out;

		&.open {
			max-width: 360px;
			margin-left: -0.8px;
		}
	}

	.dg-dp-legend {
		padding: 12px;
	}

	.dg-dp-legend-header {
		@apply mb-2;
		font-size: 12pt;

		p {
			font-size: 14pt;
		}
	}

	.dg-dp-legend-content {
		p {
			@apply m-0 font-medium leading-4;
			font-size: 11pt;
		}
	}

	.dg-dp-legend-price-categories {
		@apply justify-between;
	}

	.dg-dp-legend-price-category {
		@apply mx-0 px-1;
		width: 75px;
		font-size: 7.5pt;

		span {
			@apply font-normal;
			font-size: 7pt;
		}
	}

	.separator-line {
		display: none;
	}

	.dg-dp-month {
		@apply w-full border-none;
		padding: 10px;
	}

	.dg-dp-square {
		font-size: 11px;

		&.dg-dp-date {
			span {
				font-size: 9px;
			}
		}
	}

	dc-datepicker {
		.dg-dp-square {
			&.dg-dp-navigation {
				&:not(.dg-dp-disabled):hover {
					@apply bg-transparent text-dp-blue;
				}
			}

			&.dg-dp-date {
				&:not(.dg-dp-disabled):hover {
					@apply bg-transparent text-dp-blue;
				}
			}
		}
	}
}

@media #{$xsmall} {
	.dg-calendar {
		@apply inset-0 h-auto w-auto opacity-0 transition-none;
		z-index: -10000;
		transition: margin-left 0.25s ease-in-out;

		&.open {
			@apply ml-0 opacity-100;
			z-index: 10;
		}
	}

	.dg-dp-legend {
		padding: 12px 12px 0 12px;
	}

	.dg-dp-legend-header {
		@apply mb-2;
		font-size: 12pt;

		p {
			font-size: 14pt;
		}
	}

	.dg-dp-legend-content {
		p {
			@apply m-0 font-medium leading-4;
			font-size: 11pt;
		}
	}

	.dg-dp-legend-price-categories {
		@apply justify-between;
	}

	.dg-dp-legend-price-category {
		@apply mx-0 px-1;
		width: 77px;
		font-size: 7.5pt;

		span {
			@apply font-normal;
			font-size: 7pt;
		}
	}

	.separator-line {
		display: none;
	}

	.dg-dp-months-container {
		@apply block w-full overflow-x-auto;
		-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.dg-dp-month {
		@apply mx-auto border-none;
		padding: 10px;
		max-width: 100%;
		width: 415px;
		height: 390px;
	}

	.dg-dp-closer {
		@apply absolute z-50 flex items-center justify-center rounded-full bg-white font-semibold text-brand-secondary;
		font-size: 30px;
		top: 0.2rem;
		right: 0.25rem;
		width: 4rem;
		height: 4rem;
	}

	dc-datepicker {
		.dg-dp-unit-nav {
			@apply w-full flex-wrap justify-center whitespace-normal;
			height: 45px;
		}

		.dg-dp-square {
			height: 50px;

			&.dg-dp-month-back,
			&.dg-dp-month-forward {
				width: 3rem;

				&:not(.dg-dp-disabled) {
					&:hover {
						&:after {
							@apply text-dp-red;
						}
					}
				}
			}

			&.dg-dp-month-back {
				@apply ml-auto;
			}

			&.dg-dp-navigation {
				&:not(.dg-dp-disabled):hover {
					@apply bg-transparent text-dp-blue;
				}
			}

			&.dg-dp-date {
				&:not(.dg-dp-disabled):hover {
					@apply bg-transparent text-dp-blue;
				}
			}

			&.dg-dp-date {
				span {
					font-size: 9px;
				}
			}
		}
	}
}
