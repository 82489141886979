﻿@charset "UTF-8";

@font-face {
    font-family: "jetsmart-icons";
    src: url("fonts/jetsmart-icons.eot");
    src: url("fonts/jetsmart-icons.eot?#iefix") format("embedded-opentype"),
        url("fonts/jetsmart-icons.woff2") format("woff2"),
        url("fonts/jetsmart-icons.woff") format("woff"),
        url("fonts/jetsmart-icons.ttf") format("truetype"),
        url("fonts/jetsmart-icons.svg#jetsmart-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.jt-icon {
    font-family: "jetsmart-icons";
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.jt-transfer:before {
    content: "\e901";
}

.jt-agency:before {
    content: "\e900";
}

.jt-auto:before {
    content: "\e95b";
}

.jt-flight-2:before {
    content: "\e95c";
}

.jt-sleep:before {
    content: "\e95d";
}

.jt-arrow-down:before {
    content: "\e95e";
}

.jt-arrow-left:before {
    content: "\e95f";
}

.jt-arrow-right:before {
    content: "\e960";
}

.jt-arrow-up:before {
    content: "\e961";
}

.jt-blind:before {
    content: "\e962";
}

.jt-bookings:before {
    content: "\e963";
}

.jt-calendar:before {
    content: "\e964";
}

.jt-call:before {
    content: "\e965";
}

.jt-check-in:before {
    content: "\e966";
}

.jt-circle-baggage:before {
    content: "\e967";
}

.jt-circle-call:before {
    content: "\e968";
}

.jt-circle-check-in:before {
    content: "\e969";
}

.jt-circle-duration:before {
    content: "\e96a";
}

.jt-circle-no:before {
    content: "\e96b";
}

.jt-circle-ok:before {
    content: "\e96c";
}

.jt-circle-payment:before {
    content: "\e96d";
}

.jt-circle-plane:before {
    content: "\e96e";
}

.jt-circle-question:before {
    content: "\e96f";
}

.jt-circle-refresh:before {
    content: "\e970";
}

.jt-circle-seat:before {
    content: "\e971";
}

.jt-circle-small-seat:before {
    content: "\e972";
}

.jt-circle-wheelchair:before {
    content: "\e973";
}

.jt-close:before {
    content: "\e974";
}

.jt-cocktail:before {
    content: "\e975";
}

.jt-coffee:before {
    content: "\e976";
}

.jt-credit-card:before {
    content: "\e977";
}

.jt-deaf:before {
    content: "\e978";
}

.jt-details:before {
    content: "\e979";
}

.jt-document:before {
    content: "\e97a";
}

.jt-edit:before {
    content: "\e97b";
}

.jt-edit-2:before {
    content: "\e97c";
}

.jt-facebook-logo:before {
    content: "\e97d";
}

.jt-faq-baggage:before {
    content: "\e97e";
}

.jt-faq-check-in:before {
    content: "\e97f";
}

.jt-faq-credit-card:before {
    content: "\e980";
}

.jt-faq-plane:before {
    content: "\e981";
}

.jt-faq-question:before {
    content: "\e982";
}

.jt-faq-refresh:before {
    content: "\e983";
}

.jt-faq-seat:before {
    content: "\e984";
}

.jt-faq-time:before {
    content: "\e985";
}

.jt-faq-wheelchair:before {
    content: "\e986";
}

.jt-flight:before {
    content: "\e987";
}

.jt-flight-in:before {
    content: "\e988";
}

.jt-flight-out:before {
    content: "\e989";
}

.jt-flight-ticket:before {
    content: "\e98a";
}

.jt-food:before {
    content: "\e98b";
}

.jt-gift:before {
    content: "\e98c";
}

.jt-globe:before {
    content: "\e98d";
}

.jt-hand-baggage:before {
    content: "\e98e";
}

.jt-hotel:before {
    content: "\e98f";
}

.jt-info:before {
    content: "\e990";
}

.jt-instagram-logo:before {
    content: "\e991";
}

.jt-insurance:before {
    content: "\e992";
}

.jt-large-baggage:before {
    content: "\e993";
}

.jt-large-seat:before {
    content: "\e994";
}

.jt-letter:before {
    content: "\e995";
}

.jt-lock:before {
    content: "\e996";
}

.jt-man-person:before {
    content: "\e997";
}

.jt-minus-sign:before {
    content: "\e998";
}

.jt-person:before {
    content: "\e999";
}

.jt-pin:before {
    content: "\e99a";
}

.jt-plus-sign:before {
    content: "\e99b";
}

.jt-print:before {
    content: "\e99c";
}

.jt-register:before {
    content: "\e99d";
}

.jt-search:before {
    content: "\e99e";
}

.jt-seat:before {
    content: "\e99f";
}

.jt-seat-large:before {
    content: "\e9a0";
}

.jt-security:before {
    content: "\e9a1";
}

.jt-sign-in:before {
    content: "\e9a2";
}

.jt-sign-out:before {
    content: "\e9a3";
}

.jt-size-large-baggage:before {
    content: "\e9a4";
}

.jt-size-large-cabin-baggage:before {
    content: "\e9a5";
}

.jt-size-small-baggage:before {
    content: "\e9a6";
}

.jt-small-baggage:before {
    content: "\e9a7";
}

.jt-sport:before {
    content: "\e9a8";
}

.jt-time:before {
    content: "\e9a9";
}

.jt-toilette:before {
    content: "\e9aa";
}

.jt-traveller:before {
    content: "\e9ab";
}

.jt-twitter-logo:before {
    content: "\e9ac";
}

.jt-view:before {
    content: "\e9ad";
}

.jt-voucher:before {
    content: "\e9ae";
}

.jt-warning:before {
    content: "\e9af";
}

.jt-wheelchair:before {
    content: "\e9b0";
}

.jt-women-person:before {
    content: "\e9b1";
}

.jt-youtube-logo:before {
    content: "\e9b2";
}
