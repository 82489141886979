$brand-primary: #0a396d;
$brand-secondary: #b92234;
$button-size: 15px;

.dg-modal {
	display: none;
}

.dg-vanilla-modal {
	.dg-modal {
		display: block;
		position: fixed;
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.6);
		z-index: -1;
		opacity: 0;
		transition: opacity 0.2s, z-index 0s 0.2s;
		text-align: center;
		overflow: hidden;
		overflow-y: auto;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		& > * {
			display: inline-block;
			white-space: normal;
			vertical-align: middle;
			text-align: left;
		}
		&:before {
			display: inline-block;
			overflow: hidden;
			width: 0;
			height: 100%;
			vertical-align: middle;
			content: "";
		}
	}
	&.dg-modal-visible {
		.dg-modal {
			z-index: 99999;
			opacity: 1;
			transition: opacity 0.2s;
		}
	}
}

.dg-modal-inside {
	width: 650px;
	max-width: 95%;
	height: 550px;
	background: #fff;
	border-radius: 15px;
	position: relative;

	&.dg-shorter {
		width: 600px;
		height: 254px;
	}
}

.dg-modal-info-bar {
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #00a2bb;
	color: #fff;
	font-family: "Lato", sans-serif;
	font-size: 20px;
	text-align: center;

	&.dg-smaller-info-bar {
		font-size: 18px;
		position: relative;
	}
}

.dg-modal-form {
	position: relative;
	margin-top: 10px;

	&.pull-up {
		top: -20px;
	}

	.form-group {
		margin: 0 0 25px;
	}

	.dg-rounded-primary-btn {
		margin: 15px auto;
	}

	[type="radio"] {
		&:checked {
			+ label {
				color: #0a396d;
			}
		}
	}
}

.dg-modal-banner {
	.dg-caption {
		@apply absolute flex flex-col items-center whitespace-nowrap;
		top: 75px;
		right: 95px;
	}

	img {
		max-width: 100%;
	}

	span {
		@apply leading-none text-white block uppercase;
		font-family: "ClanOT-Black", sans-serif;
		text-align: center;

		&:nth-child(1) {
			font-size: 26px;
		}

		&:nth-child(2) {
			font-size: 26px;
		}

		&:nth-child(3) {
			font-size: 54px;
			margin-top: 12px;
		}

		&.dg-large-modal-title {
			@apply absolute leading-none whitespace-normal font-semibold;
			text-align: center;
			font-size: 48px;
			max-width: 330px;
			top: 80px;
			right: 55px;
		}
	}
}

[data-dg-modal-close] {
	@apply absolute flex items-center justify-center leading-none cursor-pointer font-semibold text-white rounded-full;
	right: 25px;
	top: 15px;
	width: 56px;
	height: 56px;
	font-size: 48px;
	border: 3px solid white;
	z-index: 1;

	&:hover {
		span {
			@apply text-white opacity-50;
		}
	}
}

.dg-unavailable-modal-title {
	color: white;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $brand-primary;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	font-family: "ClanOT-Bold", sans-serif;
	font-size: 23px;
	text-align: center;
}

.dg-unavailable-modal-content {
	margin: 30px auto 50px auto;
	color: $brand-primary;
	font-family: "ClanOT-Medium", sans-serif;
	font-size: 18px;
	text-align: center;
	line-height: 29px;
	max-width: 510px;
}

.dg-unavailable-button-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dg-unavailable-button {
	&[data-dg-modal-close] {
		position: static;
		display: flex;
		margin: 0 auto;
		top: unset;
		left: unset;
		width: 145px;
		height: 42px;
		font-size: 18px;
		font-weight: unset;
		font-family: "ClanOT-Medium", sans-serif;
		background: $brand-primary;
		color: #fff;
		border-radius: 21px;
		padding-top: 3px;
	}
}

/* MEDIA QUERIES */

@media #{$small} {
	.dg-modal-inside {
		&.dg-shorter {
			width: 484px;
			height: 218px;
		}
	}

	.dg-unavailable-modal-title {
		font-size: 20px;
	}

	.dg-unavailable-modal-content {
		font-size: 16px;
		line-height: 22px;
		margin: 30px auto;
		max-width: 420px;
	}
}

@media #{$xsmall} {
	.dg-modal-inside {
		height: auto;
		padding-bottom: 30px;

		&.dg-shorter {
			width: 240px;
			height: 240px;
		}
	}

	.dg-unavailable-modal-title {
		font-size: 18px;
	}

	.dg-unavailable-modal-content {
		font-size: 14px;
		line-height: 1.4;
		max-width: 195px;
		margin: 20px auto 25px;
	}

	.dg-unavailable-button {
		[data-dg-modal-close] {
			width: 127px;
			height: 37px;
			font-size: 14px;
		}
	}

	.dg-modal-banner {
		position: relative;

		span {
			&:nth-child(2) {
				&.dg-large-modal-title {
					top: 50%;
					left: 50%;
					text-align: center;
					font-size: 40px;
					line-height: 1.1;
					transform: translate(-50%, -50%);
					text-align: center;
				}
			}
		}

		.dg-caption {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			padding-top: 10px;

			span {
				position: relative;
				right: unset;
				left: 50%;
				transform: translateX(-50%);

				&:nth-child(1) {
					font-size: 24px;
					top: unset;
					margin-bottom: 5px;
					line-height: 1;
				}

				&:nth-child(2) {
					font-size: 24px;
					top: unset;
					margin-bottom: 5px;
					line-height: 1;
				}

				&:nth-child(3) {
					font-size: 50px;
					top: unset;
					line-height: 1;
				}
			}
		}
	}

	.dg-modal-info-bar {
		font-size: 15px;

		&.dg-smaller-info-bar {
			font-size: 14px;
		}
	}

	.dg-modal-form {
		&.pull-up {
			top: 0;
		}
	}
}
