/*
 * Text Color
 */

//Indicators
.text-error {
	color: $brand-error;
}

.text-warning {
	color: $brand-warning;
}

.text-success {
	color: $brand-success;
}

.text-info {
	color: $brand-info;
}

.text-primary {
	color: $brand-primary;
}

.text-secondary {
	color: $brand-secondary;
}
