.dg-opening-pane-1 {
	@apply bg-white h-full;
	padding: 20px 15px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;

	label[for] {
		@apply block font-body font-semibold;
		color: $dg-blue;
		margin: 10px 0 10px 15px;
		font-size: 18px;
	}
}

.dg-opening-pane-2 {
	@apply absolute top-0 bottom-0 bg-white;
	left: 100%;
	width: 255px;
	margin-left: -255px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: all 0.25s ease-in-out;
	z-index: -2;

	&.open {
		margin-left: -10px;
	}
}

/* MEDIA QUERIES */

@media #{$large} {
	.dg-opening-pane-2 {
		width: 245px;
	}
}

@media #{$medium} {
	.dg-opening-pane-2 {
		width: 225px;
		margin-left: -225px;
	}
}

@media #{$small-down} {
	.dg-location-selector {
		.dg-opening-pane-1 {
			@apply z-0 bottom-0 inset-x-0 w-auto h-auto ml-0;
			top: 80px;

			label[for] {
				color: $dg-blue;
			}

			[type="checkbox"] {
				+ label {
					@apply h-auto m-0;

					&:before {
						display: none;
					}

					&:after {
						@apply absolute vertical-center;
						content: "\e911";
						font-family: "jetsmart-home";
						right: 20px;
						font-size: 22px;
						transition: all 0.25s ease-in-out;
						color: $dg-blue;
					}
				}

				&:checked {
					+ label {
						@apply text-white;
						background: $dg-red;

						&:after {
							@apply text-white;
							transform: translateY(-50%) rotate(90deg);
						}
					}
				}
			}
		}
	}
}
