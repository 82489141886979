﻿@font-face {
	font-family: "jetsmart-home";
	src: url("fonts/jetsmart-home.eot?x2d0dm");
	src: url("fonts/jetsmart-home.eot?x2d0dm#iefix") format("embedded-opentype"),
		url("fonts/jetsmart-home.ttf?x2d0dm") format("truetype"), url("fonts/jetsmart-home.woff?x2d0dm") format("woff"),
		url("fonts/jetsmart-home.svg?x2d0dm#jetsmart-home") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="jsh-"],
[class*=" jsh-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "jetsmart-home" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.jsh-triangle-exclamation-thick:before {
	content: "\e904";
}
.jsh-plane-shield:before {
	content: "\e901";
}
.jsh-circle-hand-shield:before {
	content: "\e902";
}
.jsh-broken-circle-exclamation:before {
	content: "\e900";
}
.jsh-circle-chevron-right1:before {
	content: "\e911";
}
.jsh-circle-discount:before {
	content: "\e912";
}
.jsh-circle-group:before {
	content: "\e913";
}
.jsh-circle-play:before {
	content: "\e914";
}
.jsh-circle-smart-search:before {
	content: "\e941";
}
.jsh-pin:before {
	content: "\e942";
}
.jsh-plane:before {
	content: "\e943";
}
.jsh-search1:before {
	content: "\e944";
}
.jsh-van:before {
	content: "\e945";
}
.jsh-bed:before {
	content: "\e946";
}
.jsh-car:before {
	content: "\e947";
}
.jsh-chevron-down:before {
	content: "\e948";
}
.jsh-chevron-right1:before {
	content: "\e949";
}
.jsh-circle-bed:before {
	content: "\e94a";
}
.jsh-circle-car:before {
	content: "\e94b";
}
.jsh-circle-chevron-down:before {
	content: "\e94c";
}
.jsh-person:before {
	content: "\e903";
}
